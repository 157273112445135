import styled from 'styled-components';

type Width = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | number;

export interface HideAtProps {
  theme?: any;
  greaterThanOrEqualTo?: Width;
  lessThanOrEqualTo?: Width;
  gtOffset?: number;
  ltOffset?: number;
}

export function getHideAtStyle({
  theme,
  greaterThanOrEqualTo: minWidth,
  lessThanOrEqualTo: maxWidth,
  gtOffset: minOffset = 0,
  ltOffset: maxOffset = 0,
} : HideAtProps) {
  const breakpoints = {
    xs: theme.breakpoint_xs,
    sm: theme.breakpoint_sm,
    md: theme.breakpoint_md,
    lg: theme.breakpoint_lg,
    xl: theme.breakpoint_xl,
  };

  const getPixelValue = (width: Width, offset: number) => {
    const px = typeof width === 'string' ? breakpoints[width] : `${width}px`;

    const offsetPrefix = offset >= 0 ? '+' : '-'; 
    const offsetPx = `${Math.abs(offset)}px`;

    return `calc(${px} ${offsetPrefix} ${offsetPx})`;
  };

  const getCssMinWidth = (width: Width, offset: number) => `
  @media (min-width: ${getPixelValue(width, offset)}) {
    display: none;
  }`;

  const getCssMaxWidth = (width: Width, offset: number) => `
  @media (max-width: ${getPixelValue(width, offset)}) {
    display: none;
  }`;

  return `{
    display: block;
    ${minWidth ? getCssMinWidth(minWidth, minOffset) : ''}
    ${maxWidth ? getCssMaxWidth(maxWidth, maxOffset) : ''}
  }`;
}

export const HideAt = styled.span<{
  greaterThanOrEqualTo?: Width;
  lessThanOrEqualTo?: Width;
  gtOffset?: number;
  ltOffset?: number;
}>(getHideAtStyle);

export default HideAt;
