import React from 'react';

import { Link } from '@constellation/core';
import { StyledLink } from 'client/components/styledLink/StyledLink';

import * as routes from '../../routes/manifest';
import { AppContent } from '../AppContent';
import { MetaContentValues } from '../metaContentValues';


const accessibility: AppContent['accessibility'] = {
  metaContent: MetaContentValues.accessibility,

  pageHeading: 'Accessibility statement',
  infoPanel: {
    contentSections: [
      {
        sectionBody:
          [
            {
              text: 'BM Mortgages is committed to providing a website that is accessible to the widest possible audience. We actively work to ensure that this website is accessible and usable by people of all abilities.'
            }
          ]
      },
      {
        sectionHeading: 'How to get the most accessible experience from this website',
        sectionBody:
          [
            {
              text: 'Our website can be viewed on a range of different screen sizes and the size of text can be changed to suit different people. We have also included a search facility, sitemap and glossary, to help people find information more easily.'
            }
          ]
      },
      {
        sectionHeading: 'Changing settings',
        sectionBody:
          [
            {
              text: <>Using your web browser, you can change the size of text on this website. You can also make other helpful changes in your browser, as well as within your computer generally. To find out what else you can do, visit <Link external href='https://www.bbc.co.uk/accessibility'>My Web My Way</Link> on the BBC website.</>
            }
          ]
      },
      {
        sectionHeading: 'Accessibility limitations',
        sectionBody: [
          {
            text: 'At the time of launch, this website is not known to have any limitations which will make it difficult to access for any group of users.'
          },
        ]
      },
      {
        sectionHeading: 'Contacting us',
        sectionBody: [
          {
            text: <>We are always looking for ways to help people get the best experience from this website. If there is information you think should be included on this page, or if you experience any problem accessing the site then please <StyledLink to={routes.ContactUs}>contact us</StyledLink>.</>
          },
          {
            text: <>Please note: for advice on what information to include when you contact us, we recommending you read "<Link external href='https://www.w3.org/WAI/users/inaccessible'>Contacting Organisations about Inaccessible Websites</Link>" on the W3C website.</>
          }
        ]
      },
      {
        sectionHeading: 'Accessibility guidelines',
        sectionBody: [
          {
            text: 'All pages of this website conform to level AA of the Web Content Accessibility Guidelines 2.0. These guidelines are the internationally recognised benchmark for building accessible websites.'
          },
          {
            text: 'The Web Content Accessibility Guidelines explain how to make websites more accessible for people with disabilities. Conformity to these guidelines also makes websites more user friendly for all people.'
          }
        ]
      },
      {
        sectionHeading: 'Web standards and technologies',
        sectionBody: [
          {
            text: 'This website has been built to conform to W3C standards for HTML and CSS. These technologies are relied upon throughout the site. The site displays correctly in all popular web browsers, and degrades gracefully in older browsers.'
          },
          {
            text: <>Where other technologies such as Javascript, Flash and PDF have been used we have endeavoured to provide a meaningful alternative where possible, or provided a fallback for browsers/configurations that don't support these technologies. If you are unable to access content or functionality, please <StyledLink to={routes.ContactUs}>contact us</StyledLink> with details of the problems you are encountering.</>
          }
        ]
      },
      {
        sectionHeading: 'Further help and advice',
        sectionBody: [
          {
            text: <>If you have problems using a computer because of a disability or impairment then we recommend that you visit AbilityNet's <Link external href='https://mcmw.abilitynet.org.uk'>My Computer My Way</Link> web site which provides lots of advice on how to make your computer easier to use. <Link external href='https://www.abilitynet.org.uk'>AbilityNet</Link> are experts in the field of computing and disability and they provide a free service to individuals with disabilities to assess their computing needs (call AbilityNet free on <Link href='tel:0800 269 545'>0800 269 545</Link> for more information).</>
          }
        ]
      }
    ]
  }
}

export default accessibility;