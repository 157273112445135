import React from 'react';

import { Grid, GridItem, Heading, Paragraph } from '@constellation/core';

import { StyledBox } from './DateTextComponent.styled';

interface DateTextComponentProps {
  date: string;
  month: string;
  text: string;
  text1?: string;
}

function DateTextComponent({
  date,
  month,
  text,
  text1,
}: DateTextComponentProps) {
  return (
    <Grid>
      <GridItem sm={12} xl={3}>
        <StyledBox bgColor="neutral2" cornerRadius="default">
          <Heading size="s7" marginBottom="04">
            {date}
          </Heading>
          <Heading size="s5">{month}</Heading>
        </StyledBox>
      </GridItem>
      <GridItem sm={12} xl={9}>
        <Paragraph>{text}</Paragraph>
        <Paragraph>{text1}</Paragraph>
      </GridItem>
    </Grid>
  );
}

export default DateTextComponent;
