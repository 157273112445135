export const rateChangeCalculator = {
  heading: 'rate-change-calculator-heading',
  introParagraph: 'rate-change-calculator-intro-paragraph',
  mortgageBalanceField: 'rate-change-calculator-form-field-mortgage-balance',
  termLengthYearsField: 'rate-change-calculator-form-field-term-length-years',
  termLengthMonthsField: 'rate-change-calculator-form-field-term-length-months',
  interestField: 'rate-change-calculator-form-field-interest-rate',
  repaymentTypeRepayment: 'rate-change-calculator-form-field-repayment',
  repaymentTypeInterestOnly: 'rate-change-calculator-form-field-interest-only',
  interestFieldChange: 'rate-change-calculator-form-field-interest-rate-change',
  submitBtn: 'rate-change-calculator-submit-button',
  errorSummaryContainer: 'rate-change-calculator-error-summary-container',
  monthlyPaymentChange: 'rate-change-calculator-monthly-payment-change',
  monthlyPaymentContainer: 'rate-change-calculator-monthly-payment-container',
  monthlyPaymentParagraph:
    'rate-change-calculator-monthly-payment-change-paragraph',
  mortgageTooltip: 'rate-change-calculator-tooltip-mortgage',
  termLengthTooltipContainer:
    'rate-change-calculator-tooltip-term-lengthContainer',
  termLengthTooltip: 'rate-change-calculator-tooltip-term-length',
  interestRateTooltip: 'rate-change-calculator-tooltip-interest-rate',
  repaymentTooltip: 'rate-change-calculator-tooltip-repayment',
  interestRateChangeTooltip:
    'rate-change-calculator-tooltip-interest-rate-change',
  mortgageTooltipText1: 'rate-change-calculator-tooltip-mortgage-text1',
  termLengthTooltipText1: 'rate-change-calculator-tooltip-term-length-text1',
  interestRateTooltipText1:
    'rate-change-calculator-tooltip-interest-rate-text1',
  repaymentTooltipText1: 'rate-change-calculator-tooltip-repayment-text1',
  interestRateChangeTooltipText1:
    'rate-change-calculator-tooltip-interest-rate-change-text1',
};
