import React from 'react';

import { Link } from '@constellation/core';
import * as routes from 'client/routes/manifest';
import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../metaContentValues';

const landlordSupport: AppContent['landlordSupport'] = {
  metaContent: MetaContentValues.landlordSupport,
  pageTitle: 'Landlord tax responsibilities',
  backButtonLabel: 'Back',
  backButtonHref: routes.Home,
  taxInfoPanel: {
    contentSections: [
      {
        sectionBody: [
          {
            text: (
              <>
                When you're a landlord, you'll probably have to pay tax on your
                rental income. How much depends on how much rental income you
                receive, and your total income from other sources. You can find
                out the latest tax rates and other useful tax information at{' '}
                <Link
                  href="https://www.gov.uk/renting-out-a-property/paying-tax"
                  external
                >
                  gov.uk
                </Link>
                .
              </>
            ),
          },
          {
            subheading: 'Paying tax on rental income',
          },
          {
            text: (
              <>
                To work out how much tax you owe from your rental income, you'll
                need to complete a{' '}
                <Link
                  href="https://www.gov.uk/self-assessment-tax-returns/who-must-send-a-tax-return"
                  external
                >
                  Self Assessment tax return
                </Link>
                . You can do this online or print off a form to complete and
                return by post. If you've never done this before, you'll have to{' '}
                <Link
                  href="https://www.gov.uk/register-for-self-assessment"
                  external
                >
                  register online with HMRC
                </Link>{' '}
                first.
              </>
            ),
          },
          {
            text: "Once you've completed your Self Assessment tax return, HMRC will let you know how much you owe. You can pay by debit card, bank transfer, in-branch at your bank or building society, direct debit, or by cheque through the post. Some of these will take longer to clear than others, so make sure you're aware of timescales beforehand. You can also sometimes opt to have your tax paid through PAYE if you have another taxable income.",
          },
          {
            subheading: 'Will I need any documents to complete my tax return?',
          },
          {
            text: "Yes. Although you won't have to upload any documents, it's a good idea to have the following to hand before you start:",
          },
          {
            list: [
              'Invoices.',
              'Receipts.',
              'Bank statements.',
              'Share dividends and savings interest, if you have any.',
              'Pension contributions made.',
              'Charity donations and gift aid.',
            ],
          },
          {
            text: "If you have any other income, like employment, pension, benefits, or a redundancy payout, for example, you'll need the documents for these too. This is usually on a P60 or other document showing how much tax you've already paid this tax year.",
          },
        ],
      },
    ],
  },
  datesToBeAwareOfSection: {
    heading: 'Dates to be aware of',
    text: "There are deadlines when it comes to submitting your Self Assessment tax return and paying the money you owe. Not sticking to these deadlines can cost you, as HMRC are likely to fine you and/or add interest to your bill if you're late.",
    date1: {
      date: '31',
      month: 'January',
      text: 'This is the latest you can file your online Self Assessment tax return. It’s also the day you must pay your tax bill from the previous year by. Sometimes your bill will be split - known as paying on account - and 31 January is the deadline for your first payment on account.',
    },
    date2: {
      date: '05',
      month: 'April',
      text: 'Marks the end of the UK tax year.',
    },
    date3: {
      date: '06',
      month: 'April',
      text: 'This is the start of the new UK tax year. It’s usually when any changes in tax rates or allowances will start. You can file your Self Assessment tax return from this date.',
    },
    date4: {
      date: '31',
      month: 'July',
      text: 'You must pay your second payment on account by this date, if you have one.',
    },
    date5: {
      date: '05',
      month: 'October',
      text: 'You must register for a Self Assessment tax return by this date, if you’ve not registered before.',
    },
    date6: {
      date: '31',
      month: 'October',
      text: 'If you file your Self Assessment tax return by paper, you’ll need to do it by this date. Sometimes HMRC might tell you to file a Self Assessment tax return after a certain date. You’ve got three months from the date on the letter or email, so you might need to file sooner than 31 October.',
      text1:
        'If you miss the paper deadline, don’t panic. You can submit your tax return online instead until 31 January as long as you’ve registered.',
    },
    date7: {
      date: '30',
      month: 'December',
      text: 'This is the last day to have the tax owed on your rental income taken through PAYE in the following tax year. There are certain restrictions around this though, so you might still have to pay it separately.',
    },
  },
  penaltiesSubmissionInfoPanel: {
    contentSections: [
      {
        sectionHeading:
          'Penalties for submitting your Self Assessment  tax return after the deadline',
        sectionBody: [
          {
            text: "Even if you're one day late submitting your Self Assessment tax return, you could be fined £100, so make sure you get yours filed in plenty of time. ",
          },
          {
            subheading: 'Three months late',
          },
          {
            text: "You'll be fined £10 for every day you're late, up to 90 days, plus the £100 initial fine. The maximum fine is £1,000. ",
          },
          {
            subheading: 'Six months late ',
          },
          {
            text: "As well as the three-month penalty, you'll also be fined £300 or 5% of tax owed depending on which is the higher amount. ",
          },
          {
            subheading: '12 months late',
          },
          {
            text: "If you fail to submit your Self Assessment tax return within 12 months of the deadline, you'll receive an additional fine for £300 or 5% of tax owed. Again, this will depend on which is the higher amount. In the worst case, you could be fined 100% of the tax you owe. ",
          },
        ],
      },
    ],
  },
  penaltiesPaymentInfoPanel: {
    contentSections: [
      {
        sectionHeading: 'Penalties for paying your tax bill after the deadline',
        sectionBody: [
          {
            text: (
              <>
                If you miss the 31 January deadline for paying your tax bill,
                you'll be fined interest on the amount you owe from 01 February.
                This is usually the Bank of England (BoE) base rate plus 2.5%.{' '}
                <Link
                  href="https://www.gov.uk/government/publications/rates-and-allowances-hmrc-interest-rates-for-late-and-early-payments/rates-and-allowances-hmrc-interest-rates"
                  external
                >
                  Check the up-to-date interest rates for late payments
                </Link>
              </>
            ),
          },
          { subheading: 'After 30 days ' },
          {
            text: 'You could also be fined 5% of your outstanding tax amount.',
          },
          {
            subheading: 'After six months and 12 months',
          },
          {
            text: 'A further 5% will be added.',
          },
          {
            text: (
              <>
                These fines are on top of any received for submitting your Self
                Assessment tax return late, so they can easily add up. HMRC has
                an{' '}
                <Link
                  href="https://www.gov.uk/estimate-self-assessment-penalties"
                  external
                >
                  online calculator
                </Link>{' '}
                you can use to work out how much paying late could cost you.
              </>
            ),
          },
        ],
      },
    ],
  },
  disclaimerInfoPanel: {
    contentSections: [
      {
        sectionHeading: 'Disclaimer',
        sectionBody: [
          {
            text: 'Birmingham Midshires does not provide accounting, tax, business or legal advice. This guide has been provided for information purposes only. You should consult your own professional advisors for advice directly relating to your business or before taking action in relation to any of the content provided.',
          },
        ],
      },
    ],
  },
};

export default landlordSupport;
