import React from 'react';

import {
    ContentGroup,
    Heading,
    Main,
    Paragraph,
    Link,
    Strong,
    List,
    ListItem,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import testIds from 'client/testIds';
import { Helmet } from 'react-helmet';

import { IncomeAndExpenditurePageContent } from './IncomeAndExpenditurePage.config';
import * as routes from '../manifest';

export default function IncomeAndExpenditurePage() {
    const { pageHeading, completeForm, callUs, callUsPhoneNumber, discussSituation, howToComplete, jointAccounts, authority, viewDetails, noContact, extraHelp } =
        useContent<IncomeAndExpenditurePageContent>();

    return (
        <Main>
            <Helmet>
                <title>
                    Income and Expenditure | Knowledge Base | Birmingham Midshires
                </title>
            </Helmet>
            <ContentGroup marginTop="05" data-testid={testIds.incomeAndExpenditurePage.body}>
                <StyledLink
                    iconPosition="left"
                    data-testid="back-button-link"
                    to={routes.WorriedAboutFuturePayments}
                >Back</StyledLink>
                <Heading
                    as="h1"
                    size="s7"
                    marginTop="05"
                    data-testid={testIds.incomeAndExpenditurePage.heading}
                >
                    {pageHeading}
                </Heading>

                <Paragraph marginBottom='05'>
                    {completeForm.please}
                    <Link href={completeForm.formLink.url} external data-testid={testIds.incomeAndExpenditurePage.formLink}>{completeForm.formLink.text}</Link>
                    {completeForm.thisInformation}
                </Paragraph>

                <Paragraph marginBottom='05'>
                    {callUs}
                    <Link href={callUsPhoneNumber.href} data-testid={testIds.incomeAndExpenditurePage.callUsPhoneNumber}>{callUsPhoneNumber.tel}</Link>
                    {discussSituation}
                </Paragraph>

                <Paragraph>
                    <Strong>{howToComplete.heading}</Strong>
                </Paragraph>
                <Paragraph>{howToComplete.body1}</Paragraph>
                <List marginBottom='05'>
                    {howToComplete.bullets.map((bullet) => (
                        <ListItem key={bullet}>{bullet}</ListItem>
                    ))}
                </List>

                <Paragraph>
                    <Strong>{jointAccounts.heading}</Strong>
                </Paragraph>
                <Paragraph marginBottom='05'>{jointAccounts.body}</Paragraph>

                <Paragraph>
                    <Strong>{authority.heading}</Strong>
                </Paragraph>
                <Paragraph marginBottom='05'>
                    {authority.body1}
                </Paragraph>
                <Paragraph marginBottom='05'>
                    {authority.body2}
                    <Link href={authority.phoneNumber.href} data-testid={testIds.incomeAndExpenditurePage.authorityPhoneNumber}>{authority.phoneNumber.tel}</Link>
                    {authority.body3}
                </Paragraph>

                <Paragraph>
                    <Strong>{viewDetails.heading}</Strong>
                </Paragraph>
                <Paragraph marginBottom='05'>{viewDetails.body}</Paragraph>

                <Paragraph>
                    <Strong>{noContact.heading}</Strong>
                </Paragraph>
                <Paragraph marginBottom='05'>{noContact.body}</Paragraph>

                <Paragraph>
                    <Strong>{extraHelp.heading}</Strong>
                </Paragraph>
                <Paragraph>
                    {extraHelp.body1}
                    <Link href={extraHelp.stepChange.href} external data-testid={testIds.incomeAndExpenditurePage.stepChangeLink}>{extraHelp.stepChange.text}</Link>
                    {extraHelp.or}
                    <Link href={extraHelp.moneyWellness.href} external data-testid={testIds.incomeAndExpenditurePage.moneyWellnessLink}>{extraHelp.moneyWellness.text}</Link>
                    .
                </Paragraph>
                <Paragraph>{extraHelp.body2}</Paragraph>
                <Paragraph marginBottom='05'>{extraHelp.body3}</Paragraph>

                <Paragraph>
                    {extraHelp.body4}
                    <Link href={routes.ContactUs}>{extraHelp.getInTouch}</Link>.
                </Paragraph>
            </ContentGroup>
        </Main>
    );
}
