export const landlordSupport = {
  backButtonLink: 'back-button-link',
  taxInfoPanel: {
    sectionHeading: 'tax-info-panel-section-heading',
    sectionBody: 'tax-info-panel-section-body',
  },
  penaltiesSubmissionInfoPanel: {
    sectionHeading: 'penalties-submission-info-panel-section-heading',
    sectionBody: 'penalties-submission-info-panel-section-body',
  },
  penaltiesPaymentInfoPanel: {
    sectionHeading: 'penalties-payment-info-panel-section-heading',
    sectionBody: 'penalties-payment-info-panel-section-body',
  },
  disclaimerInfoPanel: {
    sectionHeading: 'disclaimer-info-panel-section-heading',
    sectionBody: 'disclaimer-info-panel-section-body',
  },
};
