import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../metaContentValues';

const payments: AppContent['payments'] = {
  metaContent: MetaContentValues.payments,

  pageHeading: 'Overpayments',
  infoPanel: {
    contentSections: [
      {
        sectionHeading:
          'I want to make a lump sum repayment. What do I need to do?',
        sectionBody: [
          {
            text: 'You may wish to repay some of your mortgage. This is possible however some mortgage products have an early repayment charge if this is done.',
          },
          {
            text: 'If a charge is applicable this will be shown either on your latest offer or on a transfer authority form if you changed mortgage products after your account started. If a charge applies you must make a payment to cover it.',
          },
          {
            text: 'The lump sum payment can be paid via your bank. Our details are:',
          },
          { boldText: 'Lloyds bank' },
          {
            keyValueList: [
              `Sort code: 30-00-00`,
              `Account number: 00332275`,
              `Account name: Lloyds Bank plc`,
            ],
          },
          { text: 'Please ensure the payment reference states:' },
          {
            text: 'Mortgage account number - your 14 digit mortgage account number followed by 00 or to make a payment to a sub-account replace the 00 with a two digit sub-account number. See section below on how to allocate an additional payment to a specific sub-account).',
          },
          { boldText: 'International payment details' },
          {
            keyValueList: [
              'IBAN No: GB19 LOYD 3015 9900 5800 06',
              'Reference: Mortgage Account number (this should be your 14-digit number followed by 00 or your sub-account number and surname).',
            ],
          },
        ],
      },
      {
        sectionHeading: 'Keeping your account safe',
        sectionBody: [
          {
            text: `We've introduced a new way to keep your payments even safer. The Confirmation of Payee (COP) service adds more protection to your payments and shields you from fraud and scams. It checks that all details match before your bank sends a payment. When setting up a payment to someone for the first time, you will see a pop up message showing the results of the confirmation of payee check. At the moment, some banks' systems may not show a match when you enter our account details. Please make sure that you enter the correct account details, as shown above.`,
          },
        ],
      },
      {
        sectionHeading: 'By cheque',
        sectionBody: [
          {
            text: `Cheques can be sent to our lending correspondence team at:`,
          },
          {
            keyValueList: [
              `Birmingham Midshires Mortgage Servicing`,
              `PO Box 833`,
              `Leeds`,
              `LS1 9PU`,
            ],
          },
          {
            text: `Cheques should be made payable to Birmingham Midshires followed by your name and your 14 digit mortgage account number followed by 00. If you want to make the payment to a specific part of your mortgage, replace 00 by the sub-account number for that part of your mortgage. Please note cheques may not be credited to the account on the same day that we receive them.`,
          },
        ],
      },
      {
        sectionHeading: 'Over the phone',
        sectionBody: [
          {
            text: `You can make an additional mortgage payment using your debit card for any amount between £5 and £60,000 by calling us on 0345 300 2627.`,
          },
          {
            text: `Birmingham Midshires Mortgage ServicingPlease note we can only take debit card payments from customers named on the mortgage account. We don't accept credit cards or debit Mastercards (cards beginning with a 5).`,
          },
        ],
      },
      {
        sectionHeading: 'Remember to have your mortgage account number on hand',
        sectionBody: [
          {
            text: `We'll no longer automatically update your monthly payment when you make overpayments. But you can ask us to do so at any time.`,
          },
          {
            text: `If you would like any further information please contact the lending call centre on 0345 300 2627.`,
          },
        ],
      },
      {
        sectionHeading: 'Setting up a regular overpayment',
        sectionBody: [
          {
            text: `You can set up regular overpayments by standing order from any bank using:`,
          },
          {
            keyValueList: [
              `Lloyds Bank`,
              `Sort code: 30-00-00`,
              `Account number: 00332275`,
              `Account name: Lloyds Bank plc`,
            ],
          },
          { text: `Please ensure the payment reference states:` },
          {
            text: `Your 14 digit mortgage account number followed by 00 or to make a payment to a sub-account replace the 00 with the two digit sub-account number).`,
          },
        ],
      },
      {
        sectionHeading: `How do I allocate my additional payment to a specific sub-account?`,
        sectionBody: [
          {
            text: `If you pay more than your monthly payment, we'll put what you pay towards each sub-account in the same proportions that we apply your full monthly payments to those sub-accounts. However, you can allocate your additional payment to a specific sub-account providing you have made all the monthly payments due on all other parts of your mortgage. You can do this by telling us the appropriate 2 digit sub-account number as part of your payment reference. Remember, when making additional payments you may have to pay early repayment charges if they apply.`,
          },
        ],
      },
      {
        sectionHeading: `Worked example`,
        sectionBody: [
          {
            text: `If your mortgage consists of three sub-accounts and your total monthly payment is £1000, of which:`,
          },
          {
            list: [
              `Payment on sub-account 01 £100 = 10%`,
              `Payment on sub-account 02 £700 = 70%`,
              `Payment on sub-account 03 £200 = 20%`,
            ],
          },
          {
            text: `You make a £500 additional payment using your 14 digit mortgage account number followed by 00 as the reference number. This will be split between the sub-accounts in line with your current mortgage instruction as follows:`,
          },
          {
            list: [
              `Payment on sub-account 01 (10%) = £50`,
              `Payment on sub-account 02 (70%) = £350`,
              `Payment on sub-account 03 (20%) = £100`,
            ],
          },
          {
            text: `You make a £500 additional payment and want the whole additional payment to reduce your balance on sub-account 02. Your reference number should be your 14 digit mortgage account number followed by 02. This payment will be made only to sub-account 02 as follows:`,
          },
          {
            list: [
              `Payment on sub-account 01 (0%) = £0`,
              `Payment on sub-account 02 (100%) = £500`,
              `Payment on sub-account 03 (0%) = £0`,
            ],
          },
        ],
      },
    ],
  },
};

export default payments;
