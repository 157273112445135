import React from 'react';

import { Strong } from '@constellation/core';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import { AppContent } from 'content/AppContent';

import * as routes from '../../routes/manifest';
import { MetaContentValues } from '../metaContentValues';


const termsAndConditions: AppContent['termsAndConditions'] = {
  metaContent: MetaContentValues.termsAndConditions,

  heading: 'Birmingham Midshires Website T&Cs',
  infoPanel: {
    contentSections: [
      {
        sectionBody: [
          {
            boldText: 'This Website is an official Website of Birmingham Midshires.'
          },
        ]
      },
      {
        sectionBody: [
          {
            boldText: 'You should read these Terms and Conditions ("Terms") carefully before using this Website. Using the Website indicates that you accept these Terms regardless of whether or not you choose to register with us. If you do not accept these Terms, do not use the Website. You should also read the Terms and Conditions about each service on this Website.'
          }
        ]
      },
      {
        sectionBody: [
          {
            boldText: 'Please note these Terms and Conditions ("Terms") changed on January 2018.'
          }
        ]
      },
      {
        sectionHeading: 'Welcome to our Website',
        sectionBody: [
          {
            text: <>
              This is the official Website of Birmingham Midshires (<Strong>"we"</Strong> or <Strong>"us"</Strong> or <Strong>"our"</Strong>). By accessing this Website you shall be deemed to have fully accepted these Terms.
            </>
          }
        ]
      },
      {
        sectionHeading: 'Who are we?',
        sectionBody: [
          {
            text: "Birmingham Midshires is a division of Bank of Scotland plc. Bank of Scotland plc is registered with company number SC327000. Our registered office is at The Mound, Edinburgh, EH1 1YZ. We are authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority under registration number 169628."
          }
        ]
      },
      {
        sectionHeading: 'Will these Terms or the Website change?',
        sectionBody: [
          {
            text: "We may change these Terms by changing this page at any time. We may change our Website at any time."
          }
        ]
      },
      {
        sectionHeading: 'What happens to my content?',
        sectionBody: [
          {
            text: <>
              Your personal information is covered under our <StyledLink to={routes.PrivacyPolicy}>Privacy Notice</StyledLink> and <StyledLink to={routes.CookiesPolicy}>Cookie Policy</StyledLink>. Please take a moment to read these documents. Any other material or data you transmit to the Website is not deemed confidential or exclusive. We shall not be responsible for such material or data.
            </>
          }
        ]
      },
      {
        sectionHeading: 'Can I copy parts of the Website for my records?',
        sectionBody: [
          {
            text: "You may only copy or store the Website to access our products and services. You may produce print-outs of the Website for your own personal and non-commercial use. Unless otherwise stated, we (or our licensors) own the copyright, trademarks and other intellectual property rights in the Website."
          }
        ]
      },
      {
        sectionHeading: 'Should I access third party links on the Website?',
        sectionBody: [
          {
            text: "We do not control and are not responsible for the content or availability of third party websites. We do not endorse or make any representations about third party websites. It is fully at your own risk if you decide to access any of the third party websites linked to the Website."
          }
        ]
      },
      {
        sectionHeading: 'Can I link to and from the Website?',
        sectionBody: [
          {
            text: "You may link to our Website, as long as you do not replicate the home page of the Website. Also, you must not: (a) remove, distort or otherwise alter the size or appearance of any images or logos; (b) create a frame or any other browser or border environment around the Website; (c) in any way imply that the we are endorsing any products or services other than our own; (d) misrepresent your relationship with us or present any other false information about us; (e) use any trade marks displayed on the Website without our express written permission; (f) link from a website that is not owned by you; (g) have any content on your website that is distasteful, offensive or controversial, infringes any intellectual property rights or other rights of any other person or otherwise does not comply with all applicable laws"
          }
        ]
      },
      {
        sectionHeading: 'What are my obligations around security and viruses?',
        sectionBody: [
          {
            text: "We do not guarantee that our Website will be secure or free from bugs or viruses. You must not misuse our Website by knowingly introducing material, code or programs which are malicious or technologically harmful. You must not attempt to gain unauthorised access to, or attack, our Website, the server on which our Website is stored or any server, computer or database connected to our Website."
          }
        ]
      },
      {
        sectionHeading: 'What are my and your responsibilities in relation to the Website?',
        sectionBody: [
          {
            text: "The Website only complies with UK laws. You should only use it from the UK. You must be an UK resident for the products and services to be available to you."
          },
          {
            text: "The information on this Website may not always be quite up to date or complete. You should check details about important things like price or interest rates in any service or product agreement you sign with us."
          },
          {
            text: "While we try our best to make sure the Website is available 24 hours a day, we shall not be liable if the Website is not available at any time, for any reason."
          },
          {
            text: "We shall not be liable for any direct or indirect liability for any claims, losses, demands or damages that you or a third party may incur in connection with using or not being able to use the Website. However, nothing in these terms shall impact our liability for: (a) death or personal injury caused by our negligence; or (b) fraud or fraudulent misrepresentation; or (c) any liability the extent of which cannot be excluded or limited under applicable law."
          },
          {
            text: "If you breach any of these Terms, your permission to use and/or link to the Website terminates immediately."
          },
        ]
      },
      {
        sectionHeading: 'Which law and courts will rule these terms?',
        sectionBody: [
          {
            text: "These Terms shall be ruled by and interpreted under English law. Both we and you will resolve any disputes in connection with these Terms in the English courts."
          }
        ]
      },
      {
        sectionHeading: 'Where can I contact you if I have questions?',
        sectionBody: [
          {
            text: <>
              You can find our contact details by <StyledLink to={routes.ContactUs}>clicking here</StyledLink>.
            </>
          }
        ]
      },
    ]
  }
};

export default termsAndConditions;
