import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../metaContentValues';

const rateChangeCalculator: AppContent['rateChangeCalculator'] = {
  metaContent: MetaContentValues.rateChangeCalculator,

  pageHeading: 'Rate change calculator',
  introParagraph: {
    firstText:
      'Use our rate change calculator to get an idea of how much your monthly mortgage payment might change by.',
    secontText:
      'You can find your current mortgage details in your most recent mortgage statement.',
    thirdText:
      "If your mortgage is made up of different sub-accounts or parts, you'll need to complete separate calculations for each one.",
    boldText:
      "If your mortgage is affected by a rate change, we'll always write to you to let you know your new monthly payment ahead of it being taken.",
  },
  repaymentText: {
    firstLine: 'If the interest rate changed from',
    secondLine: 'to',
    thirdLine: 'the approximate change to your monthly payment would be:',
    fourthLine: 'per month',
    illustrativePurposes: 'These figures are for illustrative purposes only.',
  },
  mortgageBalanceFieldLabel: 'Mortgage balance',
  termLengthYearsFieldLabel: 'Years',
  termLengthMonthsFieldLabel: 'Months',
  interestRateFieldLabel: 'Interest rate (%)',
  repaymentTypeLabel: 'Repayment type',
  repaymentType: {
    repayment: 'Repayment',
    interestOnly: 'Interest only',
  },
  interestRateChangeFieldLabel: 'Interest rate change (%)',
  termLengthHeading: 'Term length',
  errors: {
    title: "Something's not quite right",
    mortgageBalance: 'Please enter a value. Min £1 and max £15,000,000.',
    termLengthYears: 'Please enter a value. Min 0 and max 50.',
    termLengthMonths: 'Please enter a value. Min 0 and max 11 months.',
    interestRate: 'Please enter a value. Min 0.1% and max 15%.',
    interestRateChange: 'The combined interest rate must be greater than 0.1%',
  },
  tooltips: {
    importantHeading: 'Important to know',
    genericTooltipLabel: "What's this?",
    mortgageTooltipLabel: 'How do I work out my mortgage balance?',
    mortgageBalance: {
      text1:
        'You can find your mortgage balance on your most recent mortgage statement.',
      text2:
        "You'll need to take any payments made on your mortgage since the date of your statement into account.",
      text3:
        "If you've fallen behind with your payments on any repayment part of your mortgage, you'll need to take the arrears amount off your balance.",
      text4:
        "If your mortgage is part repayment and part interest only, you'll need to do a separate calculation for each.",
    },
    termLength: {
      text1: 'The length of time you have left to pay back your mortgage. ',
      text2:
        "If your mortgage is made up of multiple sub-accounts, you'll need to do a calculation for each.",
    },
    interestRate: {
      text1: 'This is the interest rate for your sub-account.',
      text2:
        "If your mortgage is made up of multiple sub-accounts, you'll need to do a calculation for each.",
    },
    repaymentType: {
      text1:
        'This is your current repayment method. It can be either repayment or interest only.',
      text2:
        "If you have a part repayment and part interest only mortgage, you'll need to complete a separate calculation for each.",
    },
    interestRateChange: {
      text1:
        "Use the + or - buttons to add or take away incremental changes of 0.25%. You can also just type in the actual rate change you'd like to see.",
    },
  },
};

export default rateChangeCalculator;
