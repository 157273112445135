import { MetaContent } from '../content/MetaContent';
import { MetaContentValues } from '../content/metaContentValues';
import * as routes from '../routes/manifest';

const routeToMetaConfig: Record<string, MetaContent> = {
  '': MetaContentValues.home,
  [routes.Home]: MetaContentValues.home,
  [routes.OurMortgages]: MetaContentValues.ourMortgages,
  [routes.ManageYourMortgage]: MetaContentValues.manageYourMortgage,
  [routes.MortgageCalculators]: MetaContentValues.mortgageCalculators,
  [routes.Sustainability]: MetaContentValues.sustainability,
  [routes.KnowledgeBase]: MetaContentValues.knowledgeBase,
  [routes.ContactUs]: MetaContentValues.contactUs,
  [routes.Accessibility]: MetaContentValues.accessibility,
  [routes.BankOfEnglandBaseRate]: MetaContentValues.bankOfEnglandBaseRate,
  [routes.Complaints]: MetaContentValues.complaints,
  [routes.CookiesPolicy]: MetaContentValues.cookiesPolicy,
  [routes.Eligibility]: MetaContentValues.eligibility,
  [routes.FeesAndCharges]: MetaContentValues.feesAndCharges,
  [routes.WorriedAboutFuturePayments]: MetaContentValues.worriedAboutFuturePayments,
  [routes.HowToMakeAPayment]: MetaContentValues.howToMakeAPayment,
  [routes.IncomeAndExpenditure]: MetaContentValues.incomeAndExpenditure,
  [routes.InterestAndProducts]: MetaContentValues.interestAndProducts,
  [routes.QuestionsAboutLending]: MetaContentValues.questionsAboutLending,
  [routes.MakingOverpayments]: MetaContentValues.makingOverpayments,
  [routes.Payments]: MetaContentValues.payments,
  [routes.PortfolioLandlord]: MetaContentValues.portfolioLandlord,
  [routes.PrivacyPolicy]: MetaContentValues.privacyPolicy,
  [routes.PropertyDeeds]: MetaContentValues.propertyDeeds,
  [routes.RateChangeCalculator]: MetaContentValues.rateChangeCalculator,
  [routes.MortgagePaymentCalculator]: MetaContentValues.mortgagePaymentCalculator,
  [routes.SiteMap]: MetaContentValues.siteMap,
  [routes.TermsAndConditions]: MetaContentValues.termsAndConditions,
  [routes.LandlordSupport]: MetaContentValues.landlordSupport,
};

export function getMetaTagContent(path: string): MetaContent {
  return routeToMetaConfig[path] ?? MetaContentValues.home;
}
