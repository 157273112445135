import React from "react";

import { Link, Text } from "@constellation/core";
import { StyledLink } from "client/components/styledLink/StyledLink";

import * as routes from '../../routes/manifest';
import { AppContent } from "../AppContent";
import { MetaContentValues } from "../metaContentValues";

const portfolioLandlord: AppContent['portfolioLandlord'] = {
  metaContent: MetaContentValues.portfolioLandlord,

  pageHeading: 'Portfolio landlords',
  infoPanel: {
    contentSections: [
      {
        sectionHeading: "What's a portfolio landlord?'",
        sectionBody: [
          {
            text: "If you already have 4 or more properties with buy to let mortgages, including the new application, you're a portfolio landlord.",
          },
          {
            text: 'Rules and regulations mean we have to assess the portfolio of properties and the individual property.',
          },
          {
            text: 'For applications with more than one person, the total number of buy to let mortgages for all applicants are added together. If the total is more than 4 the application will be classed as a portfolio landlord mortgage.',
          },
          {
            text: "You don't need to include any properties you or anyone else named on the application rent out without a buy to let mortgage.",
          },
        ],
      },
      {
        sectionHeading: 'Portfolio landlord criteria',
        sectionBody: [
          {
            text: 'Find out more about our portfolio landlord criteria:',
          },
          {
            list: [
              'Customers can have no more than 10 buy to let mortgaged properties, including any new application.',
              'Customers must have an income of at least £30,000 for each application from employment, self-employment, including property portfolio profit, pension income, or investment income.',
              'Total rental portfolio must have a maximum loan to value of 75%.',
              'The total rental value of the portfolio must cover our stressed rental calculations at 145%.',
              <Text>
                Any property being mortgaged with a buy to let mortgage must
                meet our standard{' '}
                <StyledLink to={routes.Eligibility}>
                  eligibility criteria
                </StyledLink>
                .
              </Text>,
            ],
          },
        ],
      },
      {
        sectionHeading: 'Ready to apply?',
        sectionBody: [
          {
            text: "Our mortgages are only available through a broker, so you'll need to talk to yours about us and they'll look after everything for you.",
          },
          {
            text: "They'll need to know certain things about the property you're planning to let out and your existing portfolio. This will include all property addresses, years they were built, when you bought them, and how much for. They'll also need to know your total gross monthly rent, estimated property valuation, and whether your properties are leasehold, and managed by an agent.",
          },
          {
            boldText: 'Not got a mortgage broker?',
          },
          {
            text: (
              <Link external href="https://www.unbiased.co.uk">
                Search for one here
              </Link>
            ),
          },
        ],
      },
    ],
  },
};

export default portfolioLandlord;
