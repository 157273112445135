import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../metaContentValues';

const mortgagePaymentCalculator: AppContent['mortgagePaymentCalculator'] = {
  metaContent: MetaContentValues.mortgagePaymentCalculator,

  pageHeading: 'Mortgage payment calculator',
  paragraphOne:
    'Use our mortgage payment calculator to get an idea of how much your monthly mortgage payment might be.',
  paragraphTwo: 'You can add another scenario for a comparison.',
  repaymentText: {
    illustrativePurposes: 'These figures are for illustrative purposes only.',
  },
  mortgageBalanceFieldLabel: 'Mortgage balance',
  termLengthYearsFieldLabel: 'Years',
  termLengthMonthsFieldLabel: 'Months',
  interestRateFieldLabel: 'Interest rate (%)',
  repaymentType: {
    repayment: 'Repayment',
    interestOnly: 'Interest only',
  },
  addAnotherScenarioButton: 'Add another scenario',
  scenarioHeadings: {
    scenarioOne: 'Scenario 1',
    scenarioTwo: 'Scenario 2',
  },
  estimatedMonthlyPayment: 'Estimated monthly payment',
  perMonth: 'per month',
  closeScenario: 'Remove',
  termLengthHeading: 'Term length',
  errors: {
    title: "Something's not quite right",
    mortgageBalance: 'Please enter a value. Min £1 and max £15,000,000.',
    termLengthYears: 'Please enter a value. Min 0 and max 50.',
    termLengthMonths: 'Please enter a value. Min 0 and max 11 months.',
    interestRate: 'Please enter a value. Min 0.1% and max 15%.',
  },
  toolTipDetails: {
    mortgageBalance: {
      title: 'How do I work out my mortgage balance?',
      details: {
        paragraphOne:
          'You can find your mortgage balance on your most recent mortgage statement.',
        paragraphTwo: `You'll need to take any payments made on your mortgage since the date of your statement into account.`,
        paragraphThree: `If you've fallen behind with your payments on any repayment part of your mortgage, you'll need to take the arrears amount off your balance.`,
        paragraphFour: 'Important to know',
        paragraphFive: `If your mortgage is part repayment and part interest only, you'll need to do a separate calculation for each.`,
      },
    },
    termLength: {
      title: 'What is this?',
      details: {
        paragraphOne:
          'The length of time you have left to pay back your mortgage.',
        paragraphTwo: 'Important to know',
        paragraphThree: ` If your mortgage is made up of multiple sub-accounts, you'll need to do a calculation for each.`,
      },
    },
    interestRate: {
      title: 'What is this?',
      details: {
        paragraphOne: 'This is the interest rate for your sub-account.',
        paragraphTwo: 'Important to know',
        paragraphThree: `If your mortgage is made up of multiple sub-accounts, you'll need to do a calculation for each.`,
      },
    },
    repaymentType: {
      title: 'What is this?',
      details: {
        paragraphOne:
          'This is your current repayment method. It can be either repayment or interest only.',
        paragraphTwo: `If you have a part repayment and part interest only mortgage, you'll need to complete a separate calculation for each.`,
      },
    },
  },
};

export default mortgagePaymentCalculator;
