import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../metaContentValues';

const incomeAndExpenditure: AppContent['incomeAndExpenditure'] = {
    metaContent: MetaContentValues.incomeAndExpenditure,
    pageHeading: 'Income and expenditure',
    completeForm: {
        please: 'Please ',
        formLink: {
            text: 'download and complete this form',
            url: 'https://www.bmmortgages.co.uk/public/income_and_expenditure_form.pdf',
            testid: 'income-form-link',
        },
        thisInformation: `. This information will help us understand the money you've got coming in and regularly going out on bills and living costs so we can provide you with the appropriate support.`,
    },
    callUs: `Once you've completed it, call us on `,
    callUsPhoneNumber: {
        tel: '0808 145 0372',
        href: 'tel:08081450372',
        testid: 'income-call-us-phone-number',
    },
    discussSituation: ` to discuss your situation. Lines are open Monday to Friday 8am-8pm and Saturday 8am-5pm. We're not open on Sundays or bank holidays.`,
    howToComplete: {
        heading: 'How to complete the form',
        body1: 'All figures should be monthly amounts. For example, if you receive a weekly benefit payment:',
        bullets: [
            'Multiply the weekly amount by 52.',
            'Divide this figure by 12.',
            'This is the monthly amount to put on the form.',
        ],
    },
    jointAccounts: {
        heading: 'Joint accounts',
        body: `Please let us know if we have your permission to share your financial information with anyone else named on the mortgage account. If you don't have a joint mortgage account, this section isn't applicable.`,
    },
    authority: {
        heading: 'How will the authority work?',
        body1: 'If we have your permission, we can get a better understanding of the household income and overall affordability. This will help us get to a realistic and affordable resolution.',
        body2: `If you decide you don't want to share your information any longer, call us on `,
        phoneNumber: {
            tel: '0345 300 2627',
            href: 'tel:03453002627',
            testid: 'income-authority-phone-number',
        },
        body3: `. Lines are open Monday to Friday 8am to 8pm and Saturday 9am to 1pm. We're not open on Sundays or bank holidays.`,
    },
    viewDetails: {
        heading: 'Who can view your income and expenditure details?',
        body: `We'll be able to access your details when you next call us about this account and any other accounts which might be in arrears.`,
    },
    noContact: {
        heading: `What will we do if we don't hear from you?`,
        body: `If we haven't had any contact from you within the agreed timescales, we'll continue to write and call you.`,
    },
    extraHelp: {
        heading: 'Getting extra help and advice',
        body1: `We'd recommend you get free, independent advice from organisations such as `,
        stepChange: {
            text: 'Step Change Debt Charity',
            href: 'https://www.stepchange.org/',
            testid: 'income-step-change-link',
        },
        or: ' or ',
        moneyWellness: {
            text: 'Money Wellness',
            href: 'https://www.moneywellness.com/',
            testid: 'income-money-wellness-link',
        },
        body2: `We can talk to these organisations on your behalf if you'd like us to. You can also discuss your situation face-to-face with one of our agents if you prefer. If you speak to a solicitor or other professional, there might be a charge.`,
        body3: `It's also worth checking whether you can get any state benefits or tax credits, which could help to increase your income. And if you have income protection insurance or something similar, this could help with your payments.`,
        body4: 'If you need any further help, please ',
        getInTouch: 'get in touch',
    },
};

export default incomeAndExpenditure;
