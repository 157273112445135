import React, { useEffect, useState } from 'react';

import { Hr } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useLocation } from '@interstellar/react-app-routing';
import testIds from 'client/testIds';

import { MenuItem } from './NavigationMenu.config';
import { MobileMenuWidth, StyledAccordion, StyledNavBox, StyledNavBoxItem } from './NavigationMenu.styled';
import { AppLayoutContent } from '../appLayout/AppLayout.config';
import { HideAt } from '../hideAt';
import { StyledLink } from '../styledLink/StyledLink';

function NavigationMenu(): React.JSX.Element {
  const {
    navigationMenu: { menuItems, menuTitle },
  } = useContent<AppLayoutContent>();


  const [isMenuOpen, setIsMenuOpen] = useState(false);

  
  const { pathname } = useLocation();
  
  const isCurrentSection = (url) => pathname.startsWith(url);

  useEffect(() => {
    // Close the menu when the location changes
    setIsMenuOpen(false);
  }, [pathname]);
  return (
    <>
      {/* mobile view */}
      <HideAt greaterThanOrEqualTo={MobileMenuWidth+1}>
        <StyledAccordion
          label={menuTitle}
          variation="standalone"
          open={isMenuOpen}
          data-testid={`${testIds.navigationMenu.mobileMenu}`}
          onChange={(isOpen) => {
            if (isOpen !== isMenuOpen) {
              setIsMenuOpen(isOpen)
            }
          }}
        >
          {menuItems.map((item: MenuItem) => (
            <div key={`${item.Url}`}>
              <StyledLink
                to={item.Url}
                data-testid={`mobile-nav-link-${item.label}`}
                weight={isCurrentSection(item.Url) ? 'bold' : 'normal'}
                underline={isCurrentSection(item.Url)}
                onClick={() => setIsMenuOpen(false)}
              >
                {item.label}
              </StyledLink>
              <Hr marginTop="03" marginBottom="03" />
            </div>
          ))}
        </StyledAccordion>
      </HideAt>
      {/* desktop view */}
      <HideAt lessThanOrEqualTo={MobileMenuWidth}>
        <StyledNavBox data-testid={testIds.navigationMenu.desktopMenu}>
          {menuItems.map((item: MenuItem) => (
            <StyledNavBoxItem
              key={item.label}
            >
              <StyledLink
                weight={isCurrentSection(item.Url) ? 'bold' : 'normal'}
                underline={isCurrentSection(item.Url)}
                to={item.Url}
                data-testid={`desktop-nav-link-${item.label}`}
              >
                {item.label}
              </StyledLink>
            </StyledNavBoxItem>
          ))}
        </StyledNavBox>
      </HideAt>
    </>
  );
}

export default NavigationMenu;
