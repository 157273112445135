import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../metaContentValues';

const sitemap: AppContent['siteMap'] = {
  metaContent: MetaContentValues.siteMap,
  pageTitle: 'Sitemap',
};

export default sitemap;
