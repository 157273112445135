export const mortgagePaymentCalculator = {
  heading: 'mortgage-payment-calculator-heading',
  mortgageBalanceField:
    'mortgage-payment-calculator-form-field-mortgage-balance',
  termLengthYearsField:
    'mortgage-payment-calculator-form-field-term-length-years',
  termLengthMonthsField:
    'mortgage-payment-calculator-form-field-term-length-months',
  interestField: 'mortgage-payment-calculator-form-field-interest-rate',
  repaymentTypeRepayment: 'mortgage-payment-calculator-form-field-repayment',
  repaymentTypeInterestOnly:
    'mortgage-payment-calculator-form-field-interest-only',
  secondScenarioMortgageBalanceField:
    'mortgage-payment-calculator-form-field-scenario-two-mortgage-balance',
  secondScenarioTermLengthYearsField:
    'mortgage-payment-calculator-form-field-scenario-two-term-length-years',
  secondScenarioTermLengthMonthsField:
    'mortgage-payment-calculator-form-field-scenario-two-term-length-months',
  secondScenarioInterestField:
    'mortgage-payment-calculator-form-field-scenario-two-interest-rate',
  secondScenarioRepaymentTypeRepayment:
    'mortgage-payment-calculator-form-field-scenario-two-repayment',
  secondScenarioRepaymentTypeInterestOnly:
    'mortgage-payment-calculator-form-field-scenario-two-interest-only',
  toggleScenarioBtn: 'mortgage-payment-calculator-toggle-scenario-button',
  removeScenarioBtn: 'mortgage-payment-calculator-remove-scenario-button',
  calculateBtn: 'mortgage-payment-calculator-calculate-button',
  errorSummaryContainer: 'mortgage-payment-calculator-error-summary-container',
  scenarioOnePayment: 'mortgage-payment-calculator-scenario-one-payment',
  scenarioTwoPayment: 'mortgage-payment-calculator-scenario-two-payment',
  firstScenarioContainer: 'mortgage-payment-calculator-scenario-one-container',
  secondScenarioContainer: 'mortgage-payment-calculator-scenario-two-container',
  monthlyPaymentContainer:
    'mortgage-payment-calculator-monthly-payment-container',
  monthlyPaymentChange:
    'mortgage-payment-calculator-monthly-payment-change-heading',
  mortgageBalanceFieldTooltipTitle:
    'mortgage-payment-calculator-mortgage-balance-field-tooltip-title',
  mortgageBalanceFieldTooltipParagraphOne:
    'mortgage-payment-calculator-mortgage-balance-field-tooltip-paragraph-one',
  mortgageBalanceFieldTooltipParagraphTwo:
    'mortgage-payment-calculator-mortgage-balance-field-tooltip-paragraph-two',
  mortgageBalanceFieldTooltipParagraphThree:
    'mortgage-payment-calculator-mortgage-balance-field-tooltip-paragraph-three',
  mortgageBalanceFieldTooltipParagraphFour:
    'mortgage-payment-calculator-mortgage-balance-field-tooltip-paragraph-four',
  mortgageBalanceFieldTooltipParagraphFive:
    'mortgage-payment-calculator-mortgage-balance-field-tooltip-paragraph-five',
  termLengthTooltipTitle:
    'mortgage-payment-calculator-term-length-tooltip-title',
  termLengthTooltipParagraphOne:
    'mortgage-payment-calculator-term-length-paragraph-one',
  termLengthTooltipParagraphTwo:
    'mortgage-payment-calculator-term-length-paragraph-two',
  termLengthTooltipParagraphThree:
    'mortgage-payment-calculator-term-length-paragraph-three',
  interestFieldTooltipTitle:
    'mortgage-payment-calculator-interest-field-tooltip-title',
  interestFieldTooltipParagraphOne:
    'mortgage-payment-calculator-interest-field-paragraph-one',
  interestFieldTooltipParagraphTwo:
    'mortgage-payment-calculator-interest-field-paragraph-two',
  interestFieldTooltipParagraphThree:
    'mortgage-payment-calculator-interest-field-paragraph-three',
  repaymentTypeTooltipTitle:
    'mortgage-payment-calculator-repayment-type-tooltip-title',
  repaymentTypeParagraphOne:
    'mortgage-payment-calculator-repayment-type-paragraph-one',
  repaymentTypeParagraphTwo:
    'mortgage-payment-calculator-repayment-type-paragraph-two',
  secondScenarioMortgageBalanceFieldTooltipTitle:
    'mortgage-payment-calculator-scenario-two-mortgage-balance-field-tooltip-title',
  secondScenarioMortgageBalanceFieldTooltipParagraphOne:
    'mortgage-payment-calculator-scenario-two-mortgage-balance-field-title-paragraph-one',
  secondScenarioMortgageBalanceFieldTooltipParagraphTwo:
    'mortgage-payment-calculatorscenario-two-mortgage-balance-field-tooltip-paragraph-two',
  secondScenarioMortgageBalanceFieldTooltipParagraphThree:
    'mortgage-payment-calculator-scenario-two-mortgage-balance-field-tooltip-paragraph-three',
  secondScenarioMortgageBalanceFieldTooltipParagraphFour:
    'mortgage-payment-calculator-scenario-two-mortgage-balance-field-tooltip-paragraph-four',
  secondScenarioMortgageBalanceFieldTooltipParagraphFive:
    'mortgage-payment-calculator-scenario-two-mortgage-balance-field-tooltip-paragraph-five',
  secondScenarioTermLengthTooltipTitle:
    'mortgage-payment-calculatorscenario-two-term-length-tooltip-title',
  secondScenarioTermLengthTooltipParagraphOne:
    'mortgage-payment-calculator-scenario-two-term-length-tooltip-paragraph-one',
  secondScenarioTermLengthTooltipParagraphTwo:
    'mortgage-payment-calculator-scenario-two-term-length-tooltip-paragraph-two',
  secondScenarioTermLengthTooltipParagraphThree:
    'mortgage-payment-calculator-scenario-two-term-length-tooltip-paragraph-three',
  secondScenarioInterestFieldTooltipTitle:
    'mortgage-payment-calculator-scenario-two-interest-field-tooltip-title',
  secondScenarioInterestFieldTooltipParagraphOne:
    'mortgage-payment-calculator-scenario-two-interest-field-tooltip-paragraph-one',
  secondScenarioInterestFieldTooltipParagraphTwo:
    'mortgage-payment-calculator-scenario-two-interest-field-tooltip-paragraph-two',
  secondScenarioInterestFieldTooltipParagraphThree:
    'mortgage-payment-calculator-scenario-two-interest-field-tooltip-paragraph-three',
  secondScenarioRepaymentTypeTooltipTitle:
    'mortgage-payment-calculator-scenario-two-repayment-type-tooltip-title',
  secondScenarioRepaymentTypeParagraphOne:
    'mortgage-payment-calculator-scenario-two-repayment-type-paragraph-one',
  secondScenarioRepaymentTypeParagraphTwo:
    'mortgage-payment-calculator-scenario-two-repayment-type-paragraph-two',
};
