import React from 'react';

import { Link, Strong } from '@constellation/core';

import { AppContent } from '../AppContent';
import { MetaContentValues } from '../metaContentValues';

const complaints: AppContent['complaints'] = {
  metaContent: MetaContentValues.complaints,

  pageHeading: 'Complaints',
  byPhoneSection: {
    title: 'By phone',
    text: (
      <>
        Call us on <Link href="tel:0345 300 2627">0345 300 2627</Link>. Our
        lines are open Monday to Friday 8am - 8pm and Saturday 9am - 1pm.
      </>
    ),
  },
  byPostSection: {
    title: 'By Post',
    writeToText: <Strong>Write to:</Strong>,
    address1: 'Customer Services Mortgages',
    address2: 'Birmingham Midshires',
    address3: 'Pendeford Business Park',
    address4: 'Wobaston Road',
    address5: 'Wolverhampton',
    address6: 'WV9 5HA',
    includeText: <Strong>Please include:</Strong>,
    includeItem1:
      'Your full name, address, and postcode as it appears on your account.',
    includeItem2: 'Your contact number and the best time for us to call you.',
  },
  infoPanel: {
    contentSections: [
      {
        sectionHeading: 'Our complaints policy',
        sectionBody: [
          {
            text: "We always try to provide you with the highest standards of service. However, sometimes things go wrong. When this happens, we encourage customers to tell us about it, so we can put things right. We'll do all we can to resolve any problems and make sure you receive a quick and fair response to any complaints.",
          },
        ],
      },
      {
        sectionHeading: 'Complaint timescales',
        sectionBody: [
          {
            text: "If we haven't resolved your complaint within four weeks of receiving it, we'll send you a letter to let you know.",
          },
          {
            text: "You can also ask to have your complaint reviewed by the Financial Ombudsman Service (FOS) if we haven't resolved it after eight weeks or you feel we haven't resolved it properly. There's no charge for using their service.",
          },
          {
            text: (
              <>
                You can find out more by visiting the{' '}
                <Link external href="https://www.financial-ombudsman.org.uk">
                  Financial Ombudsman Service website
                </Link>
                .
              </>
            ),
          },
          {
            text: (
              <>
                Our complaints handling procedures meet the standards set by the
                Financial Conduct Authority (FCA). The FCA have published
                consumer guidance on how to make a complaint. You can download a
                copy from the FCA website or contact them on{' '}
                <Link href="tel:0800 111 6768">0800 111 6768</Link>.
              </>
            ),
          },
        ],
      },
      {
        sectionHeading: 'Complaints data',
        sectionBody: [
          {
            text: "We're committed to openness and publish details of the complaints data we last reported to the FCA.",
          },
          {
            text: (
              <>
                <Link
                  external
                  href="https://www.lloydsbankinggroup.com/who-we-are/group-overview/complaints-data/"
                >
                  View complaints data for firms associated with BM Mortgages
                </Link>
                .
              </>
            ),
          },
        ],
      },
    ],
  },
};

export default complaints;
