import React from 'react';

import {
  ContentGroup,
  Heading,
  Main,
  Paragraph,
  Text,
  Strong,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { InfoPanel } from 'client/components/infoPanel';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import testIds from 'client/testIds';
import { Helmet } from 'react-helmet';

import { HowToMakeAPaymentContent } from './HowToMakeAPaymentPage.config';
import * as routes from '../manifest';

export default function HowToMakeAPaymentPage() {
  const { pageHeading, infoPanel, payByCheque, internationalPayments } =
    useContent<HowToMakeAPaymentContent>();

  return (
    <Main>
      <Helmet>
        <title>
          How to make a payment to your mortgage | Knowledge base | Birmingham
          Midshires{' '}
        </title>
      </Helmet>
      <ContentGroup marginTop="05">
        <StyledLink
          iconPosition="left"
          data-testid="back-button-link"
          to={routes.KnowledgeBase}
        >
          Back to Knowledge base
        </StyledLink>
        <Heading
          as="h1"
          size="s7"
          marginTop="05"
          data-testid={testIds.howToMakeAPaymentPage.heading}
        >
          {pageHeading}
        </Heading>

        <InfoPanel
          infoPanel={infoPanel}
          pageTestIds={testIds.howToMakeAPaymentPage}
        />

        <Heading size="s3" marginBottom="02" as="h3">
          {payByCheque.label}
        </Heading>
        <Paragraph>{payByCheque.firstParagraph}</Paragraph>
        <Paragraph>{payByCheque.secondParagraph}</Paragraph>

        <Paragraph marginBottom="02">
          <Strong>{payByCheque.postTo}</Strong>
        </Paragraph>
        <Paragraph marginBottom="none">
          {payByCheque.address.businessName}
        </Paragraph>
        <Paragraph marginBottom="none" marginTop="none">
          {payByCheque.address.poBox}
        </Paragraph>
        <Paragraph marginBottom="none" marginTop="none">
          {payByCheque.address.city}
        </Paragraph>
        <Paragraph marginTop="none">{payByCheque.address.postcode}</Paragraph>

        <Paragraph>{payByCheque.thirdParagraph}</Paragraph>

        <Heading size="s3" marginBottom="02" as="h3">
          {internationalPayments.label}
        </Heading>
        <Paragraph>
          <Text>{internationalPayments.ibanLabel}</Text>
          <Strong>{internationalPayments.ibanNumber}</Strong>
        </Paragraph>

        <Paragraph>{internationalPayments.firstParagraph}</Paragraph>
      </ContentGroup>
    </Main>
  );
}
