import { Box } from '@constellation/core';
import styled from 'styled-components';

export const StyledBox = styled(Box)`
  align-self: stretch;
  text-align: center;
  border: ${({ theme }) => `1px solid ${theme.color_border_default_1}`};
  border-radius: 8px;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
