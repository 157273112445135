import React from 'react';

import {
  Main,
  Heading,
  Table,
  TableRow,
  TableCol,
  Text,
  Strong,
  ListItem,
  List,
  Paragraph,
  Link,
  ContentGroup,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { InfoPanel } from 'client/components/infoPanel';
import { StyledList } from 'client/components/sectionBody/SectionBody.styled';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import {
  ItalicText,
  StyledTable,
  StyledListElement,
  StyledTableCol,
} from 'client/routes/privacy/components/PrivacyPage.Styled';
import testIds from 'client/testIds';
import { Helmet } from 'react-helmet';

import { PrivacyPageContent } from './PrivacyPage.config';

export default function PrivacyPage(): React.JSX.Element {
  const {
    pageHeading,
    firstInfoPanel,
    personalInformationTable,
    processingSpecialCategoriesTable,
    groupsOfPersonalInfo,
    groupsOfPersonalInfoTable,
    secondInfoPanel,
    bankingAndFinancialServicesList,
    insurers,
    otherServicesAndSchemes,
    generalBusiness,
    outsideCompanies,
    companyMergers,
    thirdInfoPanel,
    dataTransferOutsideOfUk,
    fourthInfoPanel,
    personalInformation,
    dataProtectionOfficer,
    unhappyWithDataUsage,
    fifthInfoPanel,
    privacyPageSectionAnchors,
    ourPromiseInfoPanel,
    sixthInfoPanel,
    companiesList,
    personalInfoBusinessManagement,
  } = useContent<PrivacyPageContent>();
  return (
    <Main>
      <Helmet>
        <title>Privacy | Birmingham Midshires </title>
      </Helmet>
      <Heading as="h1" size="s7" marginTop="05">
        {pageHeading}
      </Heading>

      <InfoPanel
        infoPanel={ourPromiseInfoPanel}
        pageTestIds={testIds.privacy.ourPromiseInfoPanel}
      />

      <StyledTable responsive headings={privacyPageSectionAnchors.headers}>
        <TableRow>
          {privacyPageSectionAnchors.paragraph1.map((paragraph, index) => (
            <TableCol
              heading={privacyPageSectionAnchors.headers[index]}
              data-testid={`ListItem-${paragraph}-${index}`}
            >
              <Strong>{paragraph}</Strong>
            </TableCol>
          ))}
        </TableRow>
        <TableRow>
          <TableCol heading={privacyPageSectionAnchors.headers[0]}>
            <ol start={1}>
              {privacyPageSectionAnchors.column1.map((column1Item, index) => (
                <StyledListElement
                  data-testid={`ListItem_${column1Item}_${index}`}
                  key={`ListItem_${column1Item.id}`}
                >
                  <Link weight="bold" href={`#${column1Item.id}`}>
                    {column1Item.text}
                  </Link>
                </StyledListElement>
              ))}
            </ol>
          </TableCol>
          <TableCol heading={privacyPageSectionAnchors.headers[1]}>
            <ol start={8}>
              {privacyPageSectionAnchors.column2.map((column2Item, index) => (
                <StyledListElement
                  data-testid={`ListItem_${column2Item}_${index}`}
                  key={`ListItem_${column2Item.id}`}
                >
                  <Link weight="bold" href={`#${column2Item.id}`}>
                    {column2Item.text}
                  </Link>
                </StyledListElement>
              ))}
            </ol>
          </TableCol>
          <TableCol heading={privacyPageSectionAnchors.headers[2]}>
            <ol start={14}>
              {privacyPageSectionAnchors.column3.map((column3Item, index) => (
                <StyledListElement
                  data-testid={`ListItem_${column3Item}_${index}`}
                  key={`ListItem_${column3Item.id}`}
                >
                  <Link weight="bold" href={`#${column3Item.id}`}>
                    {column3Item.text}
                  </Link>
                </StyledListElement>
              ))}
            </ol>
          </TableCol>
        </TableRow>
      </StyledTable>

      <ContentGroup marginTop="05">
        <InfoPanel
          infoPanel={firstInfoPanel}
          pageTestIds={testIds.privacy.firstInfoPanel}
        />
      </ContentGroup>

      {personalInformationTable.tableEntries.map((tableEntry, indexOne) => (
        <StyledTable
          caption={[<Strong>{tableEntry.heading}</Strong>]}
          headings={personalInformationTable.headers}
          width="fluid"
        >
          <TableRow key={`personal-infromation-row-${tableEntry}`}>
            <StyledTableCol
              key={`${tableEntry.heading}-reasons-for-using-table-entry`}
              heading={personalInformationTable.headers[0]}
              data-testid={`personal-infromation-listItem-${tableEntry}-${indexOne}`}
            >
              <List>
                {tableEntry.column1.map((entry, indexTwo) => (
                  <ListItem
                    key={`reasons-for-using-list-entry-${entry}`}
                    data-testid={`reasons-for-using-list-entry-${entry}-${indexTwo}`}
                  >
                    {entry}
                  </ListItem>
                ))}
              </List>
            </StyledTableCol>
            <StyledTableCol
              key={`${tableEntry.heading}-what-we-use-information-for-table-entry`}
              heading={personalInformationTable.headers[1]}
            >
              <List>
                {tableEntry.column2.map((entry, indexTwo) => (
                  <ListItem
                    key={`what-we-use-information-for-list-entry-${entry}`}
                    data-testid={`what-we-use-information-for-list-entry-${entry}-${indexTwo}`}
                  >
                    {entry}
                  </ListItem>
                ))}
              </List>
            </StyledTableCol>
            <StyledTableCol
              key={`${tableEntry.heading}-our-legitimate-interests-table-entry`}
              heading={personalInformationTable.headers[2]}
            >
              <List>
                {tableEntry.column3.map((entry, indexTwo) => (
                  <ListItem
                    key={`our-legitimate-interests-list-entry-${entry}`}
                    data-testid={`our-legitimate-interests-list-entry-${entry}-${indexTwo}`}
                  >
                    {entry}
                  </ListItem>
                ))}
              </List>
            </StyledTableCol>
          </TableRow>
        </StyledTable>
      ))}

      <StyledTable
        caption={[
          <Strong>{personalInfoBusinessManagement.row1.heading}</Strong>,
        ]}
        headings={personalInfoBusinessManagement.headers}
        width="fluid"
      >
        <TableRow
          key={`personal-infromation-bm-row1-${personalInfoBusinessManagement.row1}`}
        >
          <StyledTableCol
            key={`${personalInfoBusinessManagement.row1.heading}-reasons-for-using-table-entry`}
            heading={personalInfoBusinessManagement.headers[0]}
          >
            <List>
              {personalInfoBusinessManagement.row1.column1.map(
                (entry, index) => (
                  <ListItem
                    key={`personal-infromation-bm-row1-entry-${entry}`}
                    data-testid={`personal-infromation-bm-${entry}-${index}`}
                  >
                    {entry}
                  </ListItem>
                ),
              )}
            </List>
          </StyledTableCol>
          <StyledTableCol
            key={`${personalInfoBusinessManagement.row1.heading}-what-we-use-information-for-bm-table-entry`}
            heading={personalInfoBusinessManagement.headers[1]}
          >
            <List>
              {personalInfoBusinessManagement.row1.column2.map(
                (entry, index) => (
                  <ListItem
                    key={`what-we-use-information-for-bm-list-entry-${entry}`}
                    data-testid={`what-we-use-information-for-bm-list-entry-${entry}-${index}`}
                  >
                    {entry}
                  </ListItem>
                ),
              )}
            </List>
          </StyledTableCol>
          <StyledTableCol
            key={`${personalInfoBusinessManagement.row1.heading}-bm-our-legitimate-interests-table-entry`}
            heading={personalInfoBusinessManagement.headers[2]}
          >
            <List>
              {personalInfoBusinessManagement.row1.column3.map(
                (entry, index) => (
                  <ListItem
                    key={`bm-our-legitimate-interests-list-entry-${entry}`}
                    data-testid={`bm-our-legitimate-interests-list-entry-${entry}-${index}`}
                  >
                    {entry}
                  </ListItem>
                ),
              )}
            </List>
          </StyledTableCol>
        </TableRow>
        <TableRow
          key={`personal-infromation-bm-row1-${personalInfoBusinessManagement.row2}`}
        >
          <StyledTableCol
            key={`${personalInfoBusinessManagement.row1.heading}-reasons-for-using-table-entry`}
            heading={personalInfoBusinessManagement.headers[0]}
          >
            <List>
              {personalInfoBusinessManagement.row2.column1.map(
                (entry, index) => (
                  <ListItem
                    key={`personal-infromation-bm-row1-entry-${entry}`}
                    data-testid={`personal-infromation-bm-row1-entry-${entry}-${index}`}
                  >
                    {entry}
                  </ListItem>
                ),
              )}
            </List>
          </StyledTableCol>
          <StyledTableCol
            key={`${personalInfoBusinessManagement.row1.heading}-what-we-use-information-for-bm-table-entry`}
            heading={personalInfoBusinessManagement.headers[1]}
          >
            <List>
              {personalInfoBusinessManagement.row2.column2.map(
                (entry, index) => (
                  <ListItem
                    key={`what-we-use-information-for-bm-list-entry-${entry}`}
                    data-testid={`what-we-use-information-for-bm-list-entry-${entry}-${index}`}
                  >
                    {entry}
                  </ListItem>
                ),
              )}
            </List>
          </StyledTableCol>
          <StyledTableCol
            key={`${personalInfoBusinessManagement.row1.heading}-bm-our-legitimate-interests-table-entry`}
            heading={personalInfoBusinessManagement.headers[2]}
          >
            <List>
              {personalInfoBusinessManagement.row2.column3?.map(
                (entry, index) => (
                  <ListItem
                    key={`bm-our-legitimate-interests-list-entry-${entry}`}
                    data-testid={`bm-our-legitimate-interests-list-entry-${entry}_${index}`}
                  >
                    {entry}
                  </ListItem>
                ),
              )}
            </List>
          </StyledTableCol>
        </TableRow>
      </StyledTable>

      <Table
        width="fluid"
        responsive={false}
        caption={[<Strong>{processingSpecialCategoriesTable.header}</Strong>]}
        headings={[
          personalInformationTable.headers[0],
          personalInformationTable.headers[1],
        ]}
      >
        {processingSpecialCategoriesTable.column1.map((entry, colIndex) => (
          <TableRow key={`special-categories-row-${entry}`}>
            <StyledTableCol key={`special-categories-header-${entry}`}>
              <List>
                <ListItem
                  data-testid={`special-categories-list-item-${entry}-${colIndex}`}
                >
                  {entry}
                </ListItem>
              </List>
            </StyledTableCol>

            <StyledTableCol key={`special-categories-data-${entry}`}>
              <List>
                {processingSpecialCategoriesTable.column2[colIndex].map(
                  (item, index) => (
                    <ListItem
                      key={`our-legitimate-interests-list-entry-${item}`}
                      data-testid={`our-legitimate-interests-list-entry-${entry}-${index}`}
                    >
                      {item}
                    </ListItem>
                  ),
                )}
              </List>
            </StyledTableCol>
          </TableRow>
        ))}
      </Table>

      <Heading
        marginTop="05"
        marginBottom="02"
        as="h2"
        size="s4"
        id={groupsOfPersonalInfo.heading.id}
        data-testid={testIds.privacy.groupsOfPersonalInfo.heading}
      >
        {groupsOfPersonalInfo.heading.text}
      </Heading>
      <Strong data-testid={testIds.privacy.groupsOfPersonalInfo.subheading}>
        {groupsOfPersonalInfo.subheading}
      </Strong>
      <Paragraph
        marginTop="02"
        data-testid={testIds.privacy.groupsOfPersonalInfo.paragraph1}
      >
        {groupsOfPersonalInfo.paragraph1}
      </Paragraph>
      <Paragraph
        marginTop="02"
        data-testid={testIds.privacy.groupsOfPersonalInfo.paragraph1}
      >
        {groupsOfPersonalInfo.paragraph2}
      </Paragraph>

      <Table headings={groupsOfPersonalInfoTable.headers}>
        {groupsOfPersonalInfoTable.upperColumn1.map((_, entryIndex) => (
          <TableRow
            key={`upper-row-${groupsOfPersonalInfoTable.upperColumn1[entryIndex]}`}
          >
            <StyledTableCol
              key={`upper-personal-info-type-${groupsOfPersonalInfoTable.upperColumn1[entryIndex]}`}
              data-testid={`upper-personal-info-type-${_}-${entryIndex}`}
              heading={groupsOfPersonalInfoTable.headers[0]}
            >
              <Text>{groupsOfPersonalInfoTable.upperColumn1[entryIndex]}</Text>
            </StyledTableCol>

            <StyledTableCol
              key={`upper-personal-info-description-${groupsOfPersonalInfoTable.upperColumn2[entryIndex]}`}
              data-testid={`upper-personal-info-description-${groupsOfPersonalInfoTable.upperColumn2[entryIndex]}-${entryIndex}`}
              heading={groupsOfPersonalInfoTable.headers[1]}
            >
              <Text>{groupsOfPersonalInfoTable.upperColumn2[entryIndex]}</Text>
            </StyledTableCol>
          </TableRow>
        ))}

        <TableRow key="special-categories-row">
          <StyledTableCol
            key="special-categories-type-row"
            heading={groupsOfPersonalInfoTable.headers[0]}
            data-testid={
              testIds.privacy.groupsOfPersonalInfo.specialCategoriesType
            }
          >
            {groupsOfPersonalInfoTable.specialCategoriesType}
          </StyledTableCol>
          <StyledTableCol
            key="special-cateogries-descrption-row"
            heading={groupsOfPersonalInfoTable.headers[1]}
          >
            <Paragraph
              data-testid={
                testIds.privacy.groupsOfPersonalInfo.specialCategoriesFirstLine
              }
            >
              {groupsOfPersonalInfoTable.specialCategoriesFirstLine}
            </Paragraph>
            <List>
              {groupsOfPersonalInfoTable.specialCategoriesList.map(
                (category, index) => (
                  <ListItem
                    key={`special-category-${category}`}
                    data-testid={`special-category-${category}-${index}`}
                  >
                    {category}
                  </ListItem>
                ),
              )}
            </List>
            <Paragraph
              data-testid={
                testIds.privacy.groupsOfPersonalInfo.specialCategoriesSecondLine
              }
            >
              {groupsOfPersonalInfoTable.specialCategoriesSecondLine}
            </Paragraph>
          </StyledTableCol>
        </TableRow>

        {groupsOfPersonalInfoTable.lowerColumn1.map((_, entryIndex) => (
          <TableRow
            key={`lower-row-${groupsOfPersonalInfoTable.lowerColumn1[entryIndex]}`}
          >
            <StyledTableCol
              key={`lower-personal-info-type-${groupsOfPersonalInfoTable.lowerColumn1[entryIndex]}`}
              data-testid={`lower-personal-info-type-${groupsOfPersonalInfoTable.lowerColumn1[entryIndex]}-${entryIndex}`}
              heading={groupsOfPersonalInfoTable.headers[0]}
            >
              <Text>{groupsOfPersonalInfoTable.lowerColumn1[entryIndex]}</Text>
            </StyledTableCol>

            <StyledTableCol
              key={`lower-personal-info-description-${groupsOfPersonalInfoTable.lowerColumn2[entryIndex]}`}
              data-testid={`lower-personal-info-type-${groupsOfPersonalInfoTable.lowerColumn2[entryIndex]}-${entryIndex}`}
              heading={groupsOfPersonalInfoTable.headers[1]}
            >
              <Text>{groupsOfPersonalInfoTable.lowerColumn2[entryIndex]}</Text>
            </StyledTableCol>
          </TableRow>
        ))}
      </Table>

      <ContentGroup marginTop="05">
        <InfoPanel
          infoPanel={secondInfoPanel}
          pageTestIds={testIds.privacy.secondInfoPanel}
        />
      </ContentGroup>

      <List>
        {companiesList.normalone.map((category, index) => (
          <ListItem
            key={`companiesList-one-${category}`}
            data-testid={`companiesList-one-${category}-${index}`}
          >
            {category}
          </ListItem>
        ))}
        {companiesList.italic.map((category, index) => (
          <ListItem
            key={`companiesList-italic-${category.text}`}
            data-testid={`companiesList-italic-${category.text}-${index}`}
          >
            <>
              <Paragraph marginBottom="none">{category.text}</Paragraph>
              <ItalicText marginBottom="none">{category.italicText}</ItalicText>
            </>
          </ListItem>
        ))}
        {companiesList.normaltwo.map((category, index) => (
          <ListItem
            key={`companiesList-two-${category}`}
            data-testid={`companiesList-two-${category}-${index}`}
          >
            {category}
          </ListItem>
        ))}
      </List>
      <Paragraph>
        {companiesList.linkpart1}{' '}
        <StyledLink external to={companiesList.linkref}>
          {companiesList.linktext}
        </StyledLink>
        {companiesList.linkpart2}
      </Paragraph>

      <ContentGroup marginTop="05">
        <InfoPanel
          infoPanel={thirdInfoPanel}
          pageTestIds={testIds.privacy.secondInfoPanel}
        />
      </ContentGroup>

      <List>
        <ListItem
          key="cra-item"
          data-testid={testIds.privacy.bankingAndFinancialServicesList.craItem}
        >
          {bankingAndFinancialServicesList.normalOne[0]}
        </ListItem>
        <ListItem
          data-testid={
            testIds.privacy.bankingAndFinancialServicesList.craItemItalic
          }
          key="cra-item-one"
        >
          <>
            <Paragraph marginBottom="none">
              {bankingAndFinancialServicesList.italic[0].text}
            </Paragraph>
            <ItalicText marginBottom="none">
              {bankingAndFinancialServicesList.italic[0].italicText}
            </ItalicText>
          </>
        </ListItem>
        <ListItem
          key="linked-person-item"
          data-testid={
            testIds.privacy.bankingAndFinancialServicesList.linkedpPersonItem
          }
        >
          {bankingAndFinancialServicesList.normalOne[1]}
        </ListItem>
        <ListItem
          key="financial-services-company-item"
          data-testid={
            testIds.privacy.bankingAndFinancialServicesList
              .financialServicesCompanyitem
          }
        >
          <>
            <Paragraph marginBottom="none">
              {bankingAndFinancialServicesList.italic[1].text}
            </Paragraph>
            <ItalicText marginBottom="none">
              {bankingAndFinancialServicesList.italic[1].italicText}
            </ItalicText>
          </>
        </ListItem>
        <ListItem
          key="financial-advisors-item"
          data-testid={
            testIds.privacy.bankingAndFinancialServicesList
              .financialAdvisorsItem
          }
        >
          {bankingAndFinancialServicesList.normalOne[2]}
        </ListItem>
        <ListItem
          key="comparison-websites-item"
          data-testid={
            testIds.privacy.bankingAndFinancialServicesList
              .comparisonWebsitesItem
          }
        >
          <>
            <Paragraph marginBottom="none">
              {bankingAndFinancialServicesList.italic[2].text}
            </Paragraph>
            <ItalicText marginBottom="none">
              {bankingAndFinancialServicesList.italic[2].italicText}
            </ItalicText>
          </>
        </ListItem>
        <ListItem>{bankingAndFinancialServicesList.normalOne[3]}</ListItem>
        <ListItem
          key="employers-item"
          data-testid={
            testIds.privacy.bankingAndFinancialServicesList.employersItem
          }
        >
          <>
            <Paragraph marginBottom="none">
              {bankingAndFinancialServicesList.italic[3].text}
            </Paragraph>
            <ItalicText marginBottom="none">
              {
                bankingAndFinancialServicesList.italic[3].italicTextWithLink
                  .partone
              }
              <Link
                external
                href={
                  bankingAndFinancialServicesList.italic[3].italicTextWithLink
                    .link.url
                }
              >
                {
                  bankingAndFinancialServicesList.italic[3].italicTextWithLink
                    .link.displayText
                }
              </Link>
              {
                bankingAndFinancialServicesList.italic[3].italicTextWithLink
                  .parttwo
              }
            </ItalicText>
          </>
        </ListItem>
        <ListItem
          key="companies-item"
          data-testid={
            testIds.privacy.bankingAndFinancialServicesList.companiesItem
          }
        >
          <>
            <Paragraph marginBottom="none">
              {bankingAndFinancialServicesList.italic[4].text}
            </Paragraph>
            <ItalicText marginBottom="none">
              {bankingAndFinancialServicesList.italic[4].italicText}
            </ItalicText>
          </>
        </ListItem>
      </List>

      <Heading size="s3" as="h3" data-testid={testIds.privacy.insurers.heading}>
        {insurers.heading}
      </Heading>
      <Paragraph data-testid={testIds.privacy.insurers.paragraph}>
        {insurers.firstParagraph}
      </Paragraph>
      <StyledList>
        {insurers.listItems.map((listItem, index) => (
          <ListItem
            key={`insurer-entry-${listItem}`}
            data-testid={`insurer-entry-${listItem}-${index}`}
          >
            {listItem}
          </ListItem>
        ))}
      </StyledList>

      <Heading
        size="s3"
        as="h3"
        data-testid={testIds.privacy.otherServicesAndSchemes.heading}
      >
        {otherServicesAndSchemes.heading}
      </Heading>
      <Paragraph
        data-testid={testIds.privacy.otherServicesAndSchemes.paragraph1}
      >
        {otherServicesAndSchemes.firstParagraph}
      </Paragraph>
      <List>
        <ListItem key="other-services-item-0">
          <Paragraph
            marginBottom="none"
            data-testid={testIds.privacy.otherServicesAndSchemes.listItem}
          >
            {otherServicesAndSchemes.listItem}
          </Paragraph>
          <ItalicText>{otherServicesAndSchemes.italicListItem}</ItalicText>
        </ListItem>

        {otherServicesAndSchemes.listItems.map((listItem, index) => (
          <ListItem
            key={`other-services-item-${listItem}`}
            data-testid={`other-services-item-${listItem}-${index}`}
          >
            {listItem}
          </ListItem>
        ))}
      </List>

      <Heading
        size="s3"
        as="h3"
        data-testid={testIds.privacy.generalBusiness.heading}
      >
        {generalBusiness.heading}
      </Heading>
      <Paragraph data-testid={testIds.privacy.generalBusiness.paragraph}>
        {generalBusiness.firstParagraph}
      </Paragraph>
      <List>
        <ListItem
          key="general-business-item-0"
          data-testid={testIds.privacy.generalBusiness.item0}
        >
          {generalBusiness.listItems[0]}
          <Paragraph marginBottom="none">
            <ItalicText>{generalBusiness.italicItems[0]}</ItalicText>
          </Paragraph>
        </ListItem>
        <ListItem
          key="general-business-item-1"
          data-testid={testIds.privacy.generalBusiness.item1}
        >
          {generalBusiness.listItems[1]}
          <Paragraph marginBottom="none">
            <ItalicText>{generalBusiness.italicItems[1]}</ItalicText>
          </Paragraph>
        </ListItem>
        <ListItem
          key="general-business-item-2"
          data-testid={testIds.privacy.generalBusiness.item2}
        >
          {generalBusiness.listItems[2]}
          <Paragraph marginBottom="none">
            <ItalicText>{generalBusiness.italicItems[2]}</ItalicText>
          </Paragraph>
        </ListItem>
        <ListItem
          key="general-business-item-3"
          data-testid={testIds.privacy.generalBusiness.item3}
        >
          {generalBusiness.listItems[3]}
          <Paragraph marginBottom="none">
            <ItalicText>{generalBusiness.italicItems[3]}</ItalicText>
          </Paragraph>
        </ListItem>
        <ListItem
          key="general-business-item-4"
          data-testid={testIds.privacy.generalBusiness.item4}
        >
          {generalBusiness.listItems[4]}
          <Paragraph marginBottom="none">
            <ItalicText>{generalBusiness.italicItems[4]}</ItalicText>
          </Paragraph>
        </ListItem>
        <ListItem
          key="general-business-item-5"
          data-testid={testIds.privacy.generalBusiness.item5}
        >
          {generalBusiness.listItems[5]}
        </ListItem>
      </List>
      <Paragraph>{generalBusiness.soThatHeading}</Paragraph>
      <List>
        {generalBusiness.soThatList.map((listItem, index) => (
          <ListItem
            key={`advertisers-styled-list-${listItem}`}
            data-testid={`advertisers-styled-list-${listItem}-${index}`}
          >
            {listItem}
          </ListItem>
        ))}
      </List>
      <Paragraph>
        {generalBusiness.learnMoreLinkPart1}
        <Link href="#how-we-use-your-information">
          {generalBusiness.learnMoreLink}
        </Link>
      </Paragraph>
      <Paragraph data-testid={testIds.privacy.outsideCompanies.paragraph1}>
        {outsideCompanies.firstParagraph}
      </Paragraph>
      <Paragraph data-testid={testIds.privacy.outsideCompanies.paragraph2}>
        {outsideCompanies.secondParagraph}
      </Paragraph>
      <StyledList>
        {outsideCompanies.listItems.map((_, listItemIndex) => (
          <ListItem
            key={`outside-companies-${outsideCompanies.listItems[listItemIndex]}`}
            data-testid={`outside-companies-${outsideCompanies.listItems[listItemIndex]}-${listItemIndex}`}
          >
            <Paragraph marginBottom="none">
              {outsideCompanies.listItems[listItemIndex]}
            </Paragraph>
            <Paragraph>
              <ItalicText>
                {outsideCompanies.italicListItems[listItemIndex]}
              </ItalicText>
            </Paragraph>
          </ListItem>
        ))}
      </StyledList>

      <Heading
        size="s3"
        as="h3"
        data-testid={testIds.privacy.companyMergers.heading}
      >
        {companyMergers.heading}
      </Heading>
      <Paragraph data-testid={testIds.privacy.companyMergers.paragraph}>
        {companyMergers.firstParagraph}
      </Paragraph>
      <List>
        <ListItem
          key="company-mergers-item-0"
          data-testid={testIds.privacy.companyMergers.item0}
        >
          {companyMergers.listItems[0]}
          <Paragraph marginBottom="none">
            <ItalicText>{companyMergers.italicItem}</ItalicText>
          </Paragraph>
        </ListItem>
        <ListItem
          key="company-mergers-item-1"
          data-testid={testIds.privacy.companyMergers.item1}
        >
          {companyMergers.listItems[1]}
        </ListItem>
        <ListItem
          key="company-mergers-item-2"
          data-testid={testIds.privacy.companyMergers.item2}
        >
          {companyMergers.listItems[2]}
        </ListItem>
      </List>

      <InfoPanel
        infoPanel={fourthInfoPanel}
        pageTestIds={testIds.privacy.thirdInfoPanel}
      />

      <Heading
        id={dataTransferOutsideOfUk.sectionID}
        data-testid={testIds.privacy.dataTransfer.heading}
        as="h3"
        size="s3"
      >
        {dataTransferOutsideOfUk.heading}
      </Heading>
      <Strong data-testid={testIds.privacy.dataTransfer.subheading}>
        {dataTransferOutsideOfUk.subheading}
      </Strong>
      <Paragraph data-testid={testIds.privacy.dataTransfer.paragraph1}>
        {dataTransferOutsideOfUk.firstParagraph}
      </Paragraph>

      <StyledList>
        <ListItem
          key="data-transfer-list-item-0"
          data-testid={testIds.privacy.dataTransfer.item0}
        >
          {dataTransferOutsideOfUk.dataExceptionListItems[0]}
          <Paragraph marginTop="none" marginBottom="02">
            <ItalicText>
              {dataTransferOutsideOfUk.dataExceptionListExamples[0]}
            </ItalicText>
          </Paragraph>
        </ListItem>
        <ListItem
          key="data-transfer-list-item-1"
          data-testid={testIds.privacy.dataTransfer.item1}
        >
          {dataTransferOutsideOfUk.dataExceptionListItems[1]}
          <Paragraph marginTop="none" marginBottom="02">
            <ItalicText>
              {dataTransferOutsideOfUk.dataExceptionListExamples[1]}
            </ItalicText>
          </Paragraph>
        </ListItem>
        <ListItem
          key="data-transfer-list-item-2"
          data-testid={testIds.privacy.dataTransfer.item2}
        >
          {dataTransferOutsideOfUk.dataExceptionListItems[2]}
        </ListItem>
      </StyledList>

      <Paragraph data-testid={testIds.privacy.dataTransfer.paragraph2}>
        {dataTransferOutsideOfUk.secondParagraph}
      </Paragraph>
      <List>
        <ListItem
          key="data-transfer-safe-guard-list-item-0"
          data-testid={testIds.privacy.dataTransfer.safeGaurdItem0}
        >
          <Text>
            {dataTransferOutsideOfUk.safeguardListItems[0]}{' '}
            <Link
              external
              href={dataTransferOutsideOfUk.dataProtectionLinkHref}
            >
              {dataTransferOutsideOfUk.dataProtectionLinkLabel}
            </Link>
            .
          </Text>
        </ListItem>
        <ListItem
          key="data-transfer-safe-guard-list-item-1"
          data-testid={testIds.privacy.dataTransfer.safeGaurdItem1}
        >
          <Text>
            {dataTransferOutsideOfUk.safeguardListItems[1]}{' '}
            <Link
              external
              href={dataTransferOutsideOfUk.dataProtectionLinkForEuropeHref}
            >
              {dataTransferOutsideOfUk.dataProtectionLinkForEuropeLabel}
            </Link>
            .
          </Text>
        </ListItem>
      </List>

      <InfoPanel
        infoPanel={fifthInfoPanel}
        pageTestIds={testIds.privacy.fourthInfoPanel}
      />

      <Heading
        size="s3"
        as="h3"
        marginBottom="02"
        data-testid={testIds.privacy.personalInformation.heading}
      >
        {personalInformation.heading}
      </Heading>
      <Paragraph marginBottom="02">
        {personalInformation.firstParagraph}
      </Paragraph>
      {personalInformation.mailingAddress.map((addressItem, index) => (
        <Paragraph
          marginBottom="none"
          marginTop="none"
          data-testid={`personal-information-${addressItem}-${index}`}
        >
          {addressItem}
        </Paragraph>
      ))}

      <Heading
        size="s3"
        as="h3"
        marginBottom="02"
        marginTop="05"
        data-testid={testIds.privacy.dataProtectionOfficer.heading}
      >
        {dataProtectionOfficer.heading}
      </Heading>
      <Paragraph
        marginBottom="02"
        data-testid={testIds.privacy.dataProtectionOfficer.paragraph1}
      >
        {dataProtectionOfficer.firstParagraph}
      </Paragraph>
      {dataProtectionOfficer.mailingAddress.map((addressItem, index) => (
        <Paragraph
          marginBottom="none"
          marginTop="none"
          data-testid={`data-protection-officer-${addressItem}-${index}`}
        >
          {addressItem}
        </Paragraph>
      ))}

      <Heading
        size="s3"
        as="h3"
        marginBottom="02"
        marginTop="05"
        data-testid={testIds.privacy.unhappyWithDataUsage.heading}
      >
        {unhappyWithDataUsage.heading}
      </Heading>
      <Paragraph
        marginBottom="02"
        data-testid={testIds.privacy.unhappyWithDataUsage.paragraph1}
      >
        {unhappyWithDataUsage.firstParagraph}
      </Paragraph>
      <Paragraph
        marginBottom="02"
        data-testid={testIds.privacy.unhappyWithDataUsage.paragraph2}
      >
        {unhappyWithDataUsage.secondParagraph}
      </Paragraph>
      {unhappyWithDataUsage.mailingAddress.map((addressItem, index) => (
        <Paragraph
          marginBottom="none"
          marginTop="none"
          data-testid={`unhappy-with-outside-usage-${addressItem}-${index}`}
        >
          {addressItem}
        </Paragraph>
      ))}

      <ContentGroup marginTop="05" marginBottom="none">
        <InfoPanel
          infoPanel={sixthInfoPanel}
          pageTestIds={testIds.privacy.fifthInfoPanel}
        />
      </ContentGroup>
    </Main>
  );
}
