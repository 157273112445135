import React from 'react';

import { Link } from '@constellation/core';

import { AppContent } from '../AppContent';
import { MetaContentValues } from '../metaContentValues';

const eligibility: AppContent['eligibility'] = {
  metaContent: MetaContentValues.eligibility,

  pageHeading: 'Are you eligible?',
  infoPanelOne: {
    contentSections: [
      {
        sectionBody: [
          {
            text: 'Want to apply for one of our buy to let mortgages?',
          },
          {
            text: 'Check out some of the things about eligibility you need to know before you start.',
          },
          {
            boldText:
              'You can only apply for our buy to let mortgages through a mortgage broker.',
          },
        ],
      },
      {
        sectionHeading: 'Not got a mortgage broker?',
        sectionBody: [
          {
            text: (
              <>
                <Link external href="https://www.unbiased.co.uk/">
                  Search for one here
                </Link>
                .
              </>
            ),
          },
        ],
      },
      {
        sectionHeading: 'At least one person named on the mortgage must: ',
        sectionBody: [
          {
            list: ['Already own a property in the UK.'],
          },
        ],
      },
      {
        sectionHeading: 'Everyone named on the mortgage must be: ',
        sectionBody: [
          {
            list: [
              'Between 21 - 75 years old when they apply.',
              'A maximum of 80 years old at the end of the mortgage term.',
              'A UK resident, pay tax in the UK, and be paid in UK Sterling.',
            ],
          },
        ],
      },
    ],
  },
  tooltipText:
    'Loan to value is the percentage of borrowing you take out against your home. For example, if you have a £180,000 mortgage on a £200,000 house, the loan to value ratio would be 90%.',
  mortgageMustHaveSection: {
    mortgageMustHave: 'The mortgage must have: ',
    mortgageMustHaveListItem1: 'A maximum term of 40 years.',
    mortgageMustHaveListItem2: 'A maximum lending amount of £2,000,000.',
    mortgageMustHaveListItem3One: 'A maximum',
    mortgageMustHaveListItem3Two: 'loan to value',
    mortgageMustHaveListItem3Three: 'amount of 80%.',
  },
  propertyMustSection: {
    heading: 'The property must: ',
    list: [
      'Have a minimum value of £50,000.',
      'Be habitable, readily saleable, structurally sound, and be able to have Buildings Insurance arranged upon it.',
      'Not have a Restrictive Covenant or Resale Price Covenant registered against it.',
    ],
  },
  tenantMustSection: {
    heading: 'The tenant must not be: ',
    list: [
      'An asylum seeker, person with diplomatic immunity, or a family member.',
    ],
  },
  importantToKnow: {
    heading: 'Important to know',
    list: [
      'A maximum of 10 properties applies to all Buy to Let lending across Lloyds Banking Group.',
      "Affordable Housing or Right to Buy schemes aren't allowed.",
      "Holiday lets and live/work schemes aren't allowed.",
    ],
    eligibilityText:
      'For full eligibility criteria and useful information about our buy to let mortgages, download our Guide to mortgages on properties to be let.',
  },
  downloadOurGuideButton: {
    url: '/public/properties_to_be_let_mortgage_guide.pdf',
    label: 'Download our guide',
  },
  decisionInPrinciple: {
    heading: 'Decision in Principle',
    introText:
      "A Decision in Principle (DIP) is your first step towards getting a buy to let mortgage. It'll give you an idea of how much you could borrow without any impact on your credit score.",
    list: [
      "Tell us about all applicants' income and spending.",
      'It only takes about 15 minutes to complete.',
      "We'll give you a decision straightaway.",
    ],
  },
  mortgageBroker: (
    <>
      Speak to your{' '}
      <Link external href="https://www.unbiased.co.uk">
        mortgage broker
      </Link>{' '}
      if you're ready to complete a DIP.
    </>
  ),
};

export default eligibility;
