import React from 'react';

import {
  ContentGroup,
  List,
  ListItem,
  Paragraph,
  Strong,
} from '@constellation/core';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import { AppContent } from 'content/AppContent';

import * as routes from '../../routes/manifest';
import { MetaContentValues } from '../metaContentValues';

const cookies: AppContent['cookies'] = {
  metaContent: MetaContentValues.cookiesPolicy,

  pageHeading: 'Cookie policy',

  upperInfoPanel: {
    contentSections: [
      {
        sectionID: 'main-section',
        sectionBody: [
          { boldText: 'Last updated September 2024' },

          {
            text: 'Our Cookie policy explains how we use cookies and similar tracking technologies. It also explains the choices you can make about whether we can put some of these cookies on your computer, tablet or mobile device.',
          },
          {
            text: "In this policy, where we say 'cookies' it can also mean similar tracking technologies. Similar tracking technologies are those that collect data while you use our websites and mobile apps, and when you receive, open or respond to emails we send you. You can find more information on the tracking we use in our emails, including how you can stop it, in the Email Tracking section.",
          },
          {
            text: 'Data we collect will be held by Bank of Scotland plc which trades as Birmingham Midshires, which is part of the Lloyds Banking Group. We use this data to:',
          },
          {
            list: [
              'Protect you from fraud and keep improving security',
              'Study how people use our websites and apps and our other services. This helps us to improve them',
              'Decide which of our products, services and offers may be relevant for you',
              'Tailor the marketing you see on social media, apps and other websites.',
            ],
          },
          {
            text: (
              <>
                We may share this data with other companies in the Group. We
                also share some data with outside organisations. Some of them
                may place their own cookies on your device when you visit our
                website. You can find out more about how we share data - and who
                we share it with - in our {` `}
                <StyledLink to={routes.PrivacyPolicy}>
                  Privacy notice
                </StyledLink>
                .
              </>
            ),
          },
          {
            text: 'We do not sell the personal information we have about you to outside organisations.',
          },
        ],
      },
      {
        sectionID: 'understand-cookies',
        sectionHeading: 'Understanding cookies',
        sectionBody: [
          {
            text: "Cookies are small files that are sent to your computer, tablet or mobile device by websites when you visit them. They stay on your device, so that when you visit the website again, it knows that you've been there before and can give you a better experience. Cookies store information about your visits to that website. This information could be your choices, the type of device you used to connect to it, where you were when you connected, and the pages you visited on the site.",
          },
        ],
      },
      {
        sectionID: 'multiple-users-cookies',
        sectionHeading: 'Multiple users',
        sectionBody: [
          {
            text: 'If more than one person uses your device, the choices set up by other people will apply to you as well unless you change them. For example, if you share a computer with your family, you may see ads based on sites they have visited as well as sites you have visited.',
          },
        ],
      },
      {
        sectionID: 'sessions-and-persistent-cookies',
        sectionHeading: 'Session and Persistent cookies',
        sectionBody: [
          {
            text: 'All cookies are either session cookies or persistent cookies.',
          },
          {
            text: (
              <>
                <Strong>Session cookies </Strong>
                last for the length of your visit to a website. They delete
                themselves when you close your browser. Session cookies can help
                with security. For instance, they can keep you logged on as you
                move around a website.
              </>
            ),
          },
          {
            text: (
              <>
                <Strong>Persistent cookies </Strong>
                stay on your device when you close your browser. Persistent
                cookies are used for many jobs. For instance, they can remember
                your User ID for websites where you log on. They can also store
                your choices for when you go back to a website.
              </>
            ),
          },
          {
            text: 'Organisations find them useful to see how people use their websites. We may also use them to tailor the ads and marketing you see on social media, apps and other websites.',
          },
        ],
      },
      {
        sectionID: 'third-party-cookies',
        sectionHeading: 'First and Third-party cookies',
        sectionBody: [
          {
            text: 'Whether a cookie is first or third-party depends on where it comes from.',
          },
          {
            text: (
              <>
                <Strong>First-party cookies </Strong>
                are set by websites you go to.
              </>
            ),
          },
          {
            text: (
              <>
                <Strong>Third-party cookies </Strong>
                are set by outside organisations such as social media, search
                engines, other advertising networks and our business partners.
              </>
            ),
          },
        ],
      },
      {
        sectionID: 'third-party-partners',
        sectionHeading: 'Our Third-party partners',
        sectionBody: [
          {
            text: 'If you have given us your consent, we may allow third parties to collect your data by setting their own cookies on your device. If you use our Mobile Banking app, they may also use mobile device identifiers to personalise and measure adverts shown to you.  ',
          },
          {
            text: "You can change your choices at any time. Select the 'Cookies' link at the bottom of any page of our website and go from there.",
          },
          {
            text: 'If you use one of our mobile apps, you can also change how your mobile device identifier is used. You can do that by updating your settings in the app.',
          },
          {
            text: 'Here are links to the information notices for our main Third-Party Partners.',
          },


        ],
      },

    ],
  },
  lowerInfoPanel: {
    contentSections: [{
      sectionID: 'cookies-with-multiple-uses-section',
      sectionHeading: 'Cookies with more than one use',
      sectionBody: [
        {
          text: 'Some of our cookies collect data for more than one use. We will only use these cookies for their essential purposes unless you have given your consent to any other uses they have.',
        },
      ],
    },
    {
      sectionID: 'cookies-already-on-your-device-section',
      sectionHeading: 'Cookies that are already on your device',
      sectionBody: [
        {
          text: "Turning off one or more types of cookies won't delete any that have been downloaded in the past. We may still use data we collected up to that point, but will stop collecting new data.",
        },
      ],
    },
    {
      sectionID: 'managing-cookies-section',
      sectionHeading: 'Managing cookies choices in your browser',
      sectionBody: [
        {
          text: 'You can turn off or delete cookies in your browser. If you do this, it may affect sites that use similar cookies to us.',
        },
        {
          text: "Cookies choices you set in your browser replace any you set on a single website. For instance, if you run an ad blocker on your browser, you won't see ads that are tailored to you. This will still be true, even if you have turned on marketing cookies.",
        },
        {
          text: <>
            Find out how to manage cookies in common browsers (Internet Explorer, Chrome, Firefox and Safari) on the{` `}
            <StyledLink to='https://ico.org.uk/' external>Information Commissioners' Office (ICO) website</StyledLink>
            .
          </>
        },
      ],
    },
    {
      sectionID: 'email-tracking-section',
      sectionHeading: 'Email tracking or similar tracking technologies',
      sectionBody: [
        {
          text: 'This section explains more about the technologies we use to review how you interact with emails we send you. It explains why we do this, and how you can stop it.',
        },
        {
          text: "We review how you interact with emails we send you by using small images that you cannot see called 'pixels' within our emails. These pixels allow us to capture some information about emails we've sent you, such as:",
        },
        {
          list: [
            'When you opened the email,',
            'How many times you opened it, and',
            'The device you used to open it.',
          ],
        },
        {
          text: "This is called 'email tracking'. We use this information to help us understand how effective our emails are, and to improve our communications with you. The pixel stays in the email, but leaves nothing else on your device.",
        },
      ],
    },
    {
      sectionID: 'how-to-stop-email-tracking',
      sectionHeading: 'How to stop email tracking',
      sectionBody: [
        {
          text: 'You can stop this by:',
        },
        {
          list: [
            'Closing the email before you download any images.',
            'Setting your browser or email program to restrict or block our emails.',
          ],
        },
        {
          text: "Sometimes your browser or email program setting will automatically download images. For more details on this, you'll need to read the instructions for your browser, email program or device.",
        },
      ],
    },
    {
      sectionID: 'how-to-stop-email-tracking-for-marketing-purposes',
      sectionHeading: 'How to stop email tracking for marketing purposes',
      sectionBody: [
        {
          text: 'You can stop this by:',
        },
        {
          list: [
            'Opting out from marketing emails by reviewing your marketing choices.',
          ],
        },
        {
          text: "You can review your marketing choices in a few ways. Use your internet or mobile banking applications, visit one of our branches, or see the 'How to contact us' section in our Data Privacy Notice to speak to us about this.",
        },
      ],
    },
    {
      sectionID: 'email-link-tracking',
      sectionHeading: 'Email link tracking',
      sectionBody: [
        {
          text: "When we send you emails including links to our web pages, we track the Lloyds Banking Group web pages you visit when using those links. We'll only do this if you accept marketing cookies when you enter our site. We do not track any other non-Lloyds Banking Group sites you may also visit.",
        },
      ],
    },
    {
      sectionID: 'how-to-stop-email-link-tracking',
      sectionHeading: 'How to stop Email Link Tracking',
      sectionBody: [
        {
          text: 'You can stop this anytime by visiting our Cookie policy and rejecting marketing cookies.',
        },
      ],
    },
    {
      sectionID: 'essential-cookies-and-choice-section',
      sectionHeading: 'Essential cookies and cookies you can choose',
      sectionBody: [
        {
          text: "Different types of cookies do different jobs on our website. Some are needed to make the website work. We need your consent to use others that are not essential. You can change your choices at any time. Just click the 'Cookies' link at the end of any page.",
        },
      ],
    },]
  },
  externalLinksTable: {
    ariaLabel: 'external links table',
    testIdPrefix: 'external-links-table',
    width: 'auto',
    columns: [],
    rows: [
      {
        'textForRow1': 'Google',
        'textForRow2': (
          <>
            <ContentGroup marginBottom="04">
              <StyledLink
                to="https://policies.google.com/technologies/ads"
                external
              >
                How Google use cookies in advertising
              </StyledLink>{' '}
            </ContentGroup>
            <ContentGroup marginBottom="04">
              <StyledLink
                to="https://policies.google.com/technologies/partner-sites"
                external
              >
                How Google uses the data it collects
              </StyledLink>
            </ContentGroup>
            <ContentGroup marginBottom="04">
              <StyledLink
                to="https://policies.google.com/technologies/types"
                external
              >
                Types of cookies we use with Google
              </StyledLink>
            </ContentGroup>
          </>
        ),
      },
      {
        'textForRow1': 'Oracle Cloud',
        'textForRow2': (
          <>
            <ContentGroup marginBottom="04">
              <StyledLink
                to="https://www.oracle.com/uk/legal/privacy/privacy-policy.html"
                external
              >
                Oracle data cloud privacy policy
              </StyledLink>{' '}
            </ContentGroup>
            <ContentGroup marginBottom="04">
              <StyledLink
                to="https://datacloudoptout.oracle.com/#optout"
                external
              >
                Opt-out from Oracle cookies
              </StyledLink>
            </ContentGroup>
          </>
        ),
      },
      {
        'textForRow1': 'Meta',
        'textForRow2': (
          <>
            <ContentGroup marginBottom="04">
              <StyledLink
                to="https://www.facebook.com/policy/cookies"
                external
              >
                Meta cookies policy
              </StyledLink>{' '}
            </ContentGroup>
            <ContentGroup marginBottom="04">
              <StyledLink
                to="https://www.facebook.com/privacy/policy"
                external
              >
                Meta privacy policy
              </StyledLink>
            </ContentGroup>
          </>
        ),
      },
      {
        'textForRow1': 'Microsoft Advertising (Bing/Yahoo)',
        'textForRow2': (
          <>
            <ContentGroup marginBottom="04">
              <StyledLink
                to="https://about.ads.microsoft.com/en-us/policies/legal-privacy-and-security"
                external
              >
                Legal, privacy and security policies - Microsoft
                advertising
              </StyledLink>{' '}
            </ContentGroup>
            <ContentGroup marginBottom="04">
              <StyledLink
                to="https://privacy.microsoft.com/en-us/privacystatement"
                external
              >
                {' '}
                Microsoft privacy statement
              </StyledLink>
            </ContentGroup>
          </>
        ),
      },
      {
        'textForRow1': 'LinkedIn',
        'textForRow2': (
          <ContentGroup marginBottom="04">
            <StyledLink
              to="https://www.linkedin.com/legal/cookie-policy"
              external
            >
              LinkedIn cookie policy
            </StyledLink>{' '}
          </ContentGroup>
        ),
      },
    ],

  },
  partnersTable: {
    ariaLabel: 'our partners table',
    testIdPrefix: 'partners-table',
    width: 'auto',
    columns: ['Category', 'What they do', 'My choices'],
    rows: [
      {
        textForRow1: <Strong>Strictly necessary</Strong>,
        textForRow2: (
          <>
            <Paragraph>
              These cookies are needed to run our website, to keep it
              secure if you are logged on and to obey regulations that
              apply to us.
            </Paragraph>

            <Paragraph>
              If you are a customer, they help us know who you are so
              that you can log on and manage your accounts. They also
              help us keep your details safe and private.
            </Paragraph>
            <Paragraph>Other important jobs they do are:</Paragraph>
            <List>
              <ListItem>Help you move around the site</ListItem>
              <ListItem>
                Tell us if {` you've `} been to it before and which
                pages you went to
              </ListItem>

              <ListItem>
                Tell us how the site is working, so we can find and
                fix any problems.
              </ListItem>
            </List>
          </>
        ),
        textForRow3: (
          <Paragraph>
            You {` can't `} turn off these cookies
          </Paragraph>
        ),
      },
      {
        textForRow1: <Strong>Functional</Strong>,
        textForRow2: (
          <>
            <Paragraph>
              These cookies are used for remembering things like:
            </Paragraph>
            <List>
              <ListItem>Your user ID on the logon page</ListItem>
              <ListItem>Your region or country</ListItem>

              <ListItem>Your preferred language</ListItem>
              <ListItem>
                Accessibility options like large font or high contrast
                pages.
              </ListItem>
            </List>
          </>
        ),
        textForRow3: (
          <Paragraph>
            You {` can't `} turn off these cookies
          </Paragraph>
        ),
      },
      {
        textForRow1: <Strong>Performance</Strong>,
        textForRow2: (
          <>
            <Paragraph>
              These cookies tell us how you and our other customers
              use our website. We combine all this data together and
              study it. This helps us to:
            </Paragraph>
            <List>
              <ListItem>
                Improve the performance of our services
              </ListItem>

              <ListItem> Improve the products we provide.</ListItem>
            </List>
          </>
        ),
        textForRow3: (
          <Paragraph>
            {` We'll `} ask for your <Strong>consent</Strong> to use
            these cookies
          </Paragraph>
        ),
      },
      {
        textForRow1: (
          <Strong>Marketing and tailored advertising</Strong>
        ),
        textForRow2: (
          <ContentGroup>
            <Paragraph>
              These cookies help us understand which of our products,
              services and offers may be relevant for you. They also
              help us tailor and measure how effective our ads are on
              other websites, social media, apps and devices, like
              Smart TVs.
            </Paragraph>
            <Paragraph>
              If you provide consent, we may also share your phone
              number and email address with selected partners. When we
              share your information this way, we protect it by
              changing its characteristics to random letters and
              numbers: a {`'key'`}. Selected partners can only
              identify that this is your information if you already
              hold an account with them, and they hold the same key.
              We do this so we can show you ads on other selected
              {` partners' `} platforms.
            </Paragraph>
            <Paragraph>
              If you turn off marketing cookies, {` you'll `} still
              see our ads in your online services but these will be
              tailored only by what we already know about you.
            </Paragraph>
            <Paragraph>
              You can turn off marketing cookies in the data consents
              section of your mobile app, or on our website by
              visiting the Cookie policy page. If you turn off
              marketing cookies and any use of your information to
              tailor marketing to you, {` you'll `} still see our ads
              online, but they may not be for things that interest
              you.
            </Paragraph>
          </ContentGroup>
        ),
        textForRow3: (
          <Paragraph>
            {` We'll `} ask for your <Strong>consent</Strong> to use
            these cookies
          </Paragraph>
        ),
      },
    ],
  },


};

export default cookies;
