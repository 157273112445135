import React from 'react';

import {
  ContentGroup,
  Grid,
  GridItem,
  Heading,
  Hr,
  Main,
  Paragraph,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { InfoPanel } from 'client/components/infoPanel';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import testIds from 'client/testIds';
import { Helmet } from 'react-helmet';

import DateTextComponent from './components/DateTextComponent';
import { LandlordSupportPageContent } from './LandlordSupportPage.config';

function LandlordSupportPage(): React.JSX.Element {
  const {
    metaContent,
    pageTitle,
    backButtonLabel,
    backButtonHref,
    taxInfoPanel,
    datesToBeAwareOfSection,
    penaltiesSubmissionInfoPanel,
    penaltiesPaymentInfoPanel,
    disclaimerInfoPanel,
  } = useContent<LandlordSupportPageContent>();

  return (
    <Main>
      <Helmet>
        <title>{metaContent.title}</title>
        <meta name="description" content={metaContent.description} />
      </Helmet>
      <ContentGroup marginTop="05">
        <StyledLink
          iconPosition="left"
          data-testid={testIds.landlordSupport.backButtonLink}
          to={backButtonHref}
        >
          {backButtonLabel}
        </StyledLink>
        <Heading as="h1" size="s7" marginTop="05">
          {pageTitle}
        </Heading>
        <Hr />
        <Grid>
          <GridItem sm={12} md={8}>
            <InfoPanel
              infoPanel={taxInfoPanel}
              pageTestIds={testIds.landlordSupport.taxInfoPanel}
            />
            <Hr />
            <Heading as="h3" size="s4">
              {datesToBeAwareOfSection.heading}
            </Heading>
            <Paragraph>{datesToBeAwareOfSection.text}</Paragraph>
            <DateTextComponent
              date={datesToBeAwareOfSection.date1.date}
              month={datesToBeAwareOfSection.date1.month}
              text={datesToBeAwareOfSection.date1.text}
            />
            <Hr />
            <DateTextComponent
              date={datesToBeAwareOfSection.date2.date}
              month={datesToBeAwareOfSection.date2.month}
              text={datesToBeAwareOfSection.date2.text}
            />
            <Hr />
            <DateTextComponent
              date={datesToBeAwareOfSection.date3.date}
              month={datesToBeAwareOfSection.date3.month}
              text={datesToBeAwareOfSection.date3.text}
            />
            <Hr />
            <DateTextComponent
              date={datesToBeAwareOfSection.date4.date}
              month={datesToBeAwareOfSection.date4.month}
              text={datesToBeAwareOfSection.date4.text}
            />
            <Hr />
            <DateTextComponent
              date={datesToBeAwareOfSection.date5.date}
              month={datesToBeAwareOfSection.date5.month}
              text={datesToBeAwareOfSection.date5.text}
            />
            <Hr />
            <DateTextComponent
              date={datesToBeAwareOfSection.date6.date}
              month={datesToBeAwareOfSection.date6.month}
              text={datesToBeAwareOfSection.date6.text}
              text1={datesToBeAwareOfSection.date6.text1}
            />
            <Hr />
            <DateTextComponent
              date={datesToBeAwareOfSection.date7.date}
              month={datesToBeAwareOfSection.date7.month}
              text={datesToBeAwareOfSection.date7.text}
            />
            <Hr />
            <InfoPanel
              infoPanel={penaltiesSubmissionInfoPanel}
              pageTestIds={testIds.landlordSupport.penaltiesSubmissionInfoPanel}
            />
            <Hr />
            <InfoPanel
              infoPanel={penaltiesPaymentInfoPanel}
              pageTestIds={testIds.landlordSupport.penaltiesPaymentInfoPanel}
            />
            <Hr />
            <InfoPanel
              infoPanel={disclaimerInfoPanel}
              pageTestIds={testIds.landlordSupport.disclaimerInfoPanel}
            />
          </GridItem>
        </Grid>
      </ContentGroup>
    </Main>
  );
}

export default LandlordSupportPage;
