import { accessibility } from './accessibility';
import { bankOfEnglandBaseRate } from './bankOfEnglandBaseRate';
import { complaints } from './complaints';
import { contactUs } from './contactUs';
import { cookiesUpperInfoPanel, cookiesLowerInfoPanel } from './cookies';
import { eligibilityPage } from './eligibility';
import { feesAndCharges } from './feesAndCharges';
import { financialDifficultiesPage } from './financialDifficulties';
import { homePage } from './homePage';
import { howToMakeAPaymentPage } from './howToMakeAPayment';
import { incomeAndExpenditurePage } from './incomeAndExpenditure';
import { interestAndProductsPage } from './InterestAndPayments';
import { knowledgeBasePage } from './knowledgeBase';
import { landlordSupport } from './landlordSupport';
import { lendingPage } from './lendingPage';
import { makingOverpaymentsPage } from './makingOverpayments';
import { mortgageCalculatorsPage } from './mortgageCalculatorsPage';
import { mortgagePaymentCalculator } from './mortgagePaymentCalculator';
import { navigationMenu } from './navigationMenu';
import { ourMortgagesPage } from './ourMortgages';
import { paymentsPage } from './paymentPage';
import { portfolioLandlord } from './portfolioLandlord';
import { privacy } from './privacy';
import { propertyDeeds } from './propertyDeeds';
import { rateChangeCalculator } from './rateChangeCalculator';
import { supportPage } from './supportPage';
import { sustainability } from './sustainability';
import { termsAndConditions } from './termsAndConditions';

export default {
  content: {
    container: 'content-container',
  },
  accessibility,
  bankOfEnglandBaseRate,
  complaints,
  contactUs,
  cookiesLowerInfoPanel,
  cookiesUpperInfoPanel,
  eligibilityPage,
  feesAndCharges,
  financialDifficultiesPage,
  homePage,
  howToMakeAPaymentPage,
  incomeAndExpenditurePage,
  interestAndProductsPage,
  knowledgeBasePage,
  landlordSupport,
  lendingPage,
  makingOverpaymentsPage,
  mortgageCalculatorsPage,
  mortgagePaymentCalculator,
  navigationMenu,
  ourMortgagesPage,
  paymentsPage,
  portfolioLandlord,
  privacy,
  propertyDeeds,
  rateChangeCalculator,
  supportPage,
  sustainability,
  termsAndConditions,
};
