import React, { useState } from 'react';

import {
  Main,
  Heading,
  Button,
  TextField,
  Selector,
  SelectorItem,
  Grid,
  GridItem,
  Box,
  Hr,
  ContentGroup,
  Paragraph,
  CurrencyField,
  Tooltip,
  Strong,
} from '@constellation/core';
import { Close, Compare } from '@constellation/core/icons';
import { useContent } from '@interstellar/react-app-content';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import { useScreen } from 'client/context/ScreenContext';
import { ErrorSummaryBuilder } from 'client/routes/errorSummaryBuilder/components';
import testIds from 'client/testIds';
import { Field, Form, Formik } from 'formik';
import { Helmet } from 'react-helmet';

import { MortgagePaymentCalculatorContent } from './MortgagePaymentCalculatorPage.config';
import * as routes from '../../../manifest';
import { calculateAndFormatDifference } from '../../formatters/calculateAndFormatDifference';
import { formatNumber } from '../../formatters/formatNumber';
import { stripNonNumericalCharacters } from '../../formatters/stripNonNumericalCharacters';
import { calculateMonthlyPaymentChange } from '../../validation/calculateMonthlyPaymentChange';
import isValidInterestRate from '../../validation/isValidInterestRate';
import isValidMortgageBalance from '../../validation/isValidMortgageBalance';
import isValidTermLengthMonths from '../../validation/isValidTermLengthMonths';
import isValidTermLengthYears from '../../validation/isValidTermLengthYears';

export type FormErrors = Partial<{
  mortgageBalanceField: string;
  termLengthYearsField: string;
  termLengthMonthsField: string;
  interestRateField: string;
  secondScenarioMortgageBalanceField: string;
  secondScenarioTermLengthYearsField: string;
  secondScenarioTermLengthMonthsField: string;
  secondScenarioInterestRateField: string;
}>;

interface FormValues {
  mortgageBalanceField: string;
  termLengthYearsField: number;
  termLengthMonthsField: number;
  interestRateField: number;
  repaymentType: string;

  secondScenarioMortgageBalanceField: string;
  secondScenarioTermLengthYearsField: number;
  secondScenarioTermLengthMonthsField: number;
  secondScenarioInterestRateField: number;
  secondScenarioRepaymentType: string;
}

export const prependScenarioErrors = (
  errors: FormErrors,
  scenarioHeadings,
): FormErrors => ({
  mortgageBalanceField: errors.mortgageBalanceField
    ? `${scenarioHeadings.scenarioOne}: ${errors.mortgageBalanceField}`
    : undefined,
  termLengthYearsField: errors.termLengthYearsField
    ? `${scenarioHeadings.scenarioOne}: ${errors.termLengthYearsField}`
    : undefined,
  termLengthMonthsField: errors.termLengthMonthsField
    ? `${scenarioHeadings.scenarioOne}: ${errors.termLengthMonthsField}`
    : undefined,
  interestRateField: errors.interestRateField
    ? `${scenarioHeadings.scenarioOne}: ${errors.interestRateField}`
    : undefined,
  secondScenarioMortgageBalanceField: errors.secondScenarioMortgageBalanceField
    ? `${scenarioHeadings.scenarioTwo}: ${errors.secondScenarioMortgageBalanceField}`
    : undefined,
  secondScenarioTermLengthYearsField: errors.secondScenarioTermLengthYearsField
    ? `${scenarioHeadings.scenarioTwo}: ${errors.secondScenarioTermLengthYearsField}`
    : undefined,
  secondScenarioTermLengthMonthsField:
    errors.secondScenarioTermLengthMonthsField
      ? `${scenarioHeadings.scenarioTwo}: ${errors.secondScenarioTermLengthMonthsField}`
      : undefined,
  secondScenarioInterestRateField: errors.secondScenarioInterestRateField
    ? `${scenarioHeadings.scenarioTwo}: ${errors.secondScenarioInterestRateField}`
    : undefined,
});

export default function MortgagePaymentCalculatorPage() {
  const MortgagePaymentCalculator =
    useContent<MortgagePaymentCalculatorContent>();
  const [scenarioOnePayment, setScenarioOnePayment] =
    useState<number>(undefined);
  const [scenarioTwoPayment, setScenarioTwoPayment] =
    useState<number>(undefined);
  const [showSummaryError, setShowSummaryError] = useState<boolean>(false);
  const [showAnotherScenario, setShowAnotherScenario] =
    useState<boolean>(false);
  const { isMobile } = useScreen();
  const mobileMargin = isMobile ? '05' : 'none';
  const hasEmptyFields = (
    values: { [s: string]: unknown } | ArrayLike<unknown> | FormValues,
  ) => Object.values(values).some((value) => value === undefined);

  const validateForm = (values: FormValues) => {
    const errors: FormErrors = {};

    const { mortgageBalance, termLengthYears, termLengthMonths, interestRate } =
      MortgagePaymentCalculator.errors;

    if (!isValidMortgageBalance(values.mortgageBalanceField)) {
      errors.mortgageBalanceField = mortgageBalance;
    }
    if (!isValidTermLengthYears(values.termLengthYearsField)) {
      errors.termLengthYearsField = termLengthYears;
    }
    if (
      !isValidTermLengthMonths(
        values.termLengthYearsField,
        values.termLengthMonthsField,
      )
    ) {
      errors.termLengthMonthsField = termLengthMonths;
    }
    if (!isValidInterestRate(values.interestRateField)) {
      errors.interestRateField = interestRate;
    }
    if (
      showAnotherScenario &&
      !isValidMortgageBalance(values.secondScenarioMortgageBalanceField)
    ) {
      errors.secondScenarioMortgageBalanceField = mortgageBalance;
    }
    if (
      showAnotherScenario &&
      !isValidTermLengthYears(values.secondScenarioTermLengthYearsField)
    ) {
      errors.secondScenarioTermLengthYearsField = termLengthYears;
    }
    if (
      showAnotherScenario &&
      !isValidTermLengthMonths(
        values.secondScenarioTermLengthYearsField,
        values.secondScenarioTermLengthMonthsField,
      )
    ) {
      errors.secondScenarioTermLengthMonthsField = termLengthMonths;
    }
    if (
      showAnotherScenario &&
      !isValidInterestRate(values.secondScenarioInterestRateField)
    ) {
      errors.secondScenarioInterestRateField = interestRate;
    }
    return errors;
  };

  const handleSubmitFunction = (values: FormValues) => {
    const {
      mortgageBalanceField,
      termLengthYearsField,
      termLengthMonthsField,
      interestRateField,
      repaymentType,
      secondScenarioMortgageBalanceField,
      secondScenarioTermLengthYearsField,
      secondScenarioTermLengthMonthsField,
      secondScenarioInterestRateField,
      secondScenarioRepaymentType,
    } = values;
    setScenarioOnePayment(
      calculateMonthlyPaymentChange({
        mortgageBalanceField,
        termLengthYearsField,
        termLengthMonthsField,
        interestRateField,
        repaymentType,
      }),
    );
    if (showAnotherScenario) {
      setScenarioTwoPayment(
        calculateMonthlyPaymentChange({
          mortgageBalanceField: secondScenarioMortgageBalanceField,
          termLengthYearsField: secondScenarioTermLengthYearsField,
          termLengthMonthsField: secondScenarioTermLengthMonthsField,
          interestRateField: secondScenarioInterestRateField,
          repaymentType: secondScenarioRepaymentType,
        }),
      );
    }
  };

  const resetFieldsValue = (setFieldValue, fields) => {
    fields.forEach((fieldItem) => {
      setFieldValue(fieldItem, undefined, false);
    });
    setFieldValue('secondScenarioRepaymentType', 'repayment');
  };

  const resetFieldsTouched = (setFieldsTouched, fields) => {
    fields.forEach((fieldItem) => {
      setFieldsTouched(fieldItem, false);
    });
  };

  return (
    <Main>
      <Helmet>
        <title>
          Mortgage payment calculator | Calculators | Birmingham Midshires{' '}
        </title>
      </Helmet>

      <ContentGroup marginTop="05">
        <StyledLink
          iconPosition="left"
          data-testid="back-button-link"
          to={routes.MortgageCalculators}
        >
          Back to Mortgage calculators
        </StyledLink>
      </ContentGroup>
      <Heading
        as="h1"
        size="s7"
        marginTop="05"
        data-testid={testIds.mortgagePaymentCalculator.heading}
      >
        {MortgagePaymentCalculator.pageHeading}
      </Heading>
      <Paragraph>{MortgagePaymentCalculator.paragraphOne}</Paragraph>
      <Paragraph>{MortgagePaymentCalculator.paragraphTwo}</Paragraph>
      <Formik
        initialValues={{
          mortgageBalanceField: undefined,
          termLengthYearsField: undefined,
          termLengthMonthsField: undefined,
          interestRateField: undefined,
          repaymentType: 'repayment',
          secondScenarioMortgageBalanceField: undefined,
          secondScenarioTermLengthYearsField: undefined,
          secondScenarioTermLengthMonthsField: undefined,
          secondScenarioInterestRateField: undefined,
          secondScenarioRepaymentType: 'repayment',
        }}
        validate={validateForm}
        onSubmit={(values: FormValues) => {
          handleSubmitFunction(values);
        }}
      >
        {({
          values,
          touched,
          isValid,
          setFieldValue,
          handleSubmit,
          setFieldTouched,
          errors,
          setFieldError,
        }) => (
          <>
            {showSummaryError && !isValid && (
              <Grid>
                <GridItem md={8} lg={8}>
                  <ErrorSummaryBuilder
                    errors={prependScenarioErrors(
                      errors,
                      MortgagePaymentCalculator.scenarioHeadings,
                    )}
                    errorTitle={MortgagePaymentCalculator.errors.title}
                    testId={
                      testIds.mortgagePaymentCalculator.errorSummaryContainer
                    }
                  />
                </GridItem>
              </Grid>
            )}
            <Form noValidate>
              <Grid>
                <GridItem
                  data-testid={
                    testIds.mortgagePaymentCalculator.firstScenarioContainer
                  }
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                >
                  <Heading size="s4" marginTop="05">
                    {MortgagePaymentCalculator.scenarioHeadings.scenarioOne}
                  </Heading>
                  <Field
                    name="mortgageBalanceField"
                    as={TextField}
                    label={MortgagePaymentCalculator.mortgageBalanceFieldLabel}
                    tooltip={
                      <Tooltip
                        id="mortgageBalanceFieldToolTip"
                        data-testid={
                          testIds.mortgagePaymentCalculator
                            .mortgageBalanceFieldTooltipTitle
                        }
                        target={
                          MortgagePaymentCalculator.toolTipDetails
                            .mortgageBalance.title
                        }
                      >
                        <Paragraph
                          data-testid={
                            testIds.mortgagePaymentCalculator
                              .mortgageBalanceFieldTooltipParagraphOne
                          }
                        >
                          {
                            MortgagePaymentCalculator.toolTipDetails
                              .mortgageBalance.details.paragraphOne
                          }
                        </Paragraph>
                        <Paragraph
                          data-testid={
                            testIds.mortgagePaymentCalculator
                              .mortgageBalanceFieldTooltipParagraphTwo
                          }
                        >
                          {
                            MortgagePaymentCalculator.toolTipDetails
                              .mortgageBalance.details.paragraphTwo
                          }
                        </Paragraph>
                        <Paragraph
                          data-testid={
                            testIds.mortgagePaymentCalculator
                              .mortgageBalanceFieldTooltipParagraphThree
                          }
                        >
                          {
                            MortgagePaymentCalculator.toolTipDetails
                              .mortgageBalance.details.paragraphThree
                          }
                        </Paragraph>
                        <Paragraph
                          data-testid={
                            testIds.mortgagePaymentCalculator
                              .mortgageBalanceFieldTooltipParagraphFour
                          }
                        >
                          <Strong>
                            {' '}
                            {
                              MortgagePaymentCalculator.toolTipDetails
                                .mortgageBalance.details.paragraphFour
                            }
                          </Strong>
                        </Paragraph>
                        <Paragraph
                          data-testid={
                            testIds.mortgagePaymentCalculator
                              .mortgageBalanceFieldTooltipParagraphFive
                          }
                        >
                          {
                            MortgagePaymentCalculator.toolTipDetails
                              .mortgageBalance.details.paragraphFive
                          }
                        </Paragraph>
                      </Tooltip>
                    }
                    value={values.mortgageBalanceField}
                    maxLength={11}
                    placeholder="£"
                    inputWidth={isMobile ? 'fluid' : 'default'}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (!e.target.value.match(/[0-9]/)) {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                      }
                      setFieldValue(
                        'mortgageBalanceField',
                        formatNumber(
                          stripNonNumericalCharacters(e.target.value),
                        ) || undefined,
                      );
                    }}
                    error={
                      touched.mortgageBalanceField &&
                      errors.mortgageBalanceField
                    }
                    data-testid={
                      testIds.mortgagePaymentCalculator.mortgageBalanceField
                    }
                  />

                  <Heading
                    size="s3"
                    data-testid="interim-statement-section-heading"
                    marginBottom="02"
                  >
                    {MortgagePaymentCalculator.termLengthHeading}
                  </Heading>

                  <ContentGroup marginBottom="01">
                    <Tooltip
                      id="termLengthTooltip"
                      target={
                        MortgagePaymentCalculator.toolTipDetails.termLength
                          .title
                      }
                      data-testid={
                        testIds.mortgagePaymentCalculator.termLengthTooltipTitle
                      }
                    >
                      <Paragraph
                        data-testid={
                          testIds.mortgagePaymentCalculator
                            .termLengthTooltipParagraphOne
                        }
                      >
                        {
                          MortgagePaymentCalculator.toolTipDetails.termLength
                            .details.paragraphOne
                        }
                      </Paragraph>
                      <Paragraph
                        data-testid={
                          testIds.mortgagePaymentCalculator
                            .termLengthTooltipParagraphTwo
                        }
                      >
                        <Strong>
                          {' '}
                          {
                            MortgagePaymentCalculator.toolTipDetails.termLength
                              .details.paragraphTwo
                          }
                        </Strong>
                      </Paragraph>
                      <Paragraph
                        data-testid={
                          testIds.mortgagePaymentCalculator
                            .secondScenarioTermLengthTooltipParagraphThree
                        }
                      >
                        {
                          MortgagePaymentCalculator.toolTipDetails.termLength
                            .details.paragraphThree
                        }
                      </Paragraph>
                    </Tooltip>
                  </ContentGroup>

                  <Grid>
                    <GridItem xs={6} sm={6} md={3} lg={4}>
                      <Field
                        name="termLengthYearsField"
                        as={CurrencyField}
                        showCurrencySymbol={false}
                        integer
                        label={
                          MortgagePaymentCalculator.termLengthYearsFieldLabel
                        }
                        value={values.termLengthYearsField}
                        maxLength={2}
                        error={
                          touched.termLengthYearsField &&
                          errors.termLengthYearsField
                        }
                        data-testid={
                          testIds.mortgagePaymentCalculator.termLengthYearsField
                        }
                        inputWidth={isMobile ? 'fluid' : 'default'}
                      />
                    </GridItem>

                    <GridItem xs={6} sm={6} md={3} lg={4}>
                      <Field
                        name="termLengthMonthsField"
                        as={CurrencyField}
                        showCurrencySymbol={false}
                        label={
                          MortgagePaymentCalculator.termLengthMonthsFieldLabel
                        }
                        value={values.termLengthMonthsField}
                        maxLength={2}
                        error={
                          touched.termLengthMonthsField &&
                          errors.termLengthMonthsField
                        }
                        data-testid={
                          testIds.mortgagePaymentCalculator
                            .termLengthMonthsField
                        }
                        inputWidth={isMobile ? 'fluid' : 'default'}
                        integer
                      />
                    </GridItem>
                  </Grid>

                  <Field
                    name="interestRateField"
                    as={CurrencyField}
                    showCurrencySymbol={false}
                    tooltip={
                      <Tooltip
                        id="interestRateFieldToolTip"
                        data-testid={
                          testIds.mortgagePaymentCalculator
                            .interestFieldTooltipTitle
                        }
                        target={
                          MortgagePaymentCalculator.toolTipDetails.interestRate
                            .title
                        }
                      >
                        <Paragraph
                          data-testid={
                            testIds.mortgagePaymentCalculator
                              .interestFieldTooltipParagraphOne
                          }
                        >
                          {
                            MortgagePaymentCalculator.toolTipDetails
                              .interestRate.details.paragraphOne
                          }
                        </Paragraph>
                        <Paragraph
                          data-testid={
                            testIds.mortgagePaymentCalculator
                              .interestFieldTooltipParagraphTwo
                          }
                        >
                          <Strong>
                            {' '}
                            {
                              MortgagePaymentCalculator.toolTipDetails
                                .interestRate.details.paragraphTwo
                            }
                          </Strong>
                        </Paragraph>
                        <Paragraph
                          data-testid={
                            testIds.mortgagePaymentCalculator
                              .interestFieldTooltipParagraphThree
                          }
                        >
                          {
                            MortgagePaymentCalculator.toolTipDetails
                              .interestRate.details.paragraphThree
                          }
                        </Paragraph>
                      </Tooltip>
                    }
                    label={MortgagePaymentCalculator.interestRateFieldLabel}
                    value={values.interestRateField}
                    error={
                      touched.interestRateField && errors.interestRateField
                    }
                    data-testid={
                      testIds.mortgagePaymentCalculator.interestField
                    }
                    maxLength={5}
                    inputWidth={isMobile ? 'fluid' : 'default'}
                  />

                  <Field
                    as={Selector}
                    label="Repayment type"
                    tooltip={
                      <Tooltip
                        id="repaymentTypeToolTip"
                        target={
                          MortgagePaymentCalculator.toolTipDetails.repaymentType
                            .title
                        }
                        data-testid={
                          testIds.mortgagePaymentCalculator
                            .repaymentTypeTooltipTitle
                        }
                      >
                        <Paragraph
                          data-testid={
                            testIds.mortgagePaymentCalculator
                              .repaymentTypeParagraphOne
                          }
                        >
                          {
                            MortgagePaymentCalculator.toolTipDetails
                              .repaymentType.details.paragraphOne
                          }
                        </Paragraph>
                        <Paragraph
                          data-testid={
                            testIds.mortgagePaymentCalculator
                              .secondScenarioRepaymentTypeParagraphTwo
                          }
                        >
                          {
                            MortgagePaymentCalculator.toolTipDetails
                              .repaymentType.details.paragraphTwo
                          }
                        </Paragraph>
                      </Tooltip>
                    }
                    variation="Spaced-Auto"
                    name="repaymentType"
                    value={values.repaymentType}
                    supportiveText="Please choose one of these options"
                    inputWidth={isMobile ? 'fluid' : 'default'}
                  >
                    <Field
                      as={SelectorItem}
                      defaultChecked
                      id="repayment-type-repayment"
                      name="repaymentType"
                      value="repayment"
                      label={MortgagePaymentCalculator.repaymentType.repayment}
                      data-testid={
                        testIds.mortgagePaymentCalculator.repaymentTypeRepayment
                      }
                    >
                      {MortgagePaymentCalculator.repaymentType.repayment}
                    </Field>

                    <Field
                      as={SelectorItem}
                      id="repayment-type-interest-only"
                      name="repaymentType"
                      value="interest-only"
                      label={
                        MortgagePaymentCalculator.repaymentType.interestOnly
                      }
                      data-testid={
                        testIds.mortgagePaymentCalculator
                          .repaymentTypeInterestOnly
                      }
                    >
                      {MortgagePaymentCalculator.repaymentType.interestOnly}
                    </Field>
                  </Field>
                </GridItem>

                <GridItem sm={12} md={6} lg={4} xl={4}>
                  {!showAnotherScenario ? (
                    <ContentGroup marginTop="05" marginBottom={mobileMargin}>
                      <Button
                        onClick={() => {
                          setShowAnotherScenario(true);
                          if (scenarioOnePayment) {
                            setShowSummaryError(false);
                          }
                          resetFieldsValue(setFieldValue, [
                            'secondScenarioMortgageBalanceField',
                            'secondScenarioTermLengthYearsField',
                            'secondScenarioTermLengthMonthsField',
                            'secondScenarioInterestRateField',
                          ]);
                          resetFieldsTouched(setFieldTouched, [
                            'secondScenarioMortgageBalanceField',
                            'secondScenarioTermLengthYearsField',
                            'secondScenarioTermLengthMonthsField',
                            'secondScenarioInterestRateField',
                          ]);
                        }}
                        width="fluid"
                        variation="secondary"
                        icon={
                          <Compare color="inherit" inverse size="s2" trim />
                        }
                        iconPosition="left"
                        data-testid={
                          testIds.mortgagePaymentCalculator.toggleScenarioBtn
                        }
                      >
                        {MortgagePaymentCalculator.addAnotherScenarioButton}
                      </Button>
                    </ContentGroup>
                  ) : (
                    <Form
                      noValidate
                      data-testid={
                        testIds.mortgagePaymentCalculator
                          .secondScenarioContainer
                      }
                    >
                      <Grid>
                        <GridItem xs="auto" sm="auto" md={7} lg={7}>
                          <Heading size="s4" marginTop="05">
                            {
                              MortgagePaymentCalculator.scenarioHeadings
                                .scenarioTwo
                            }
                          </Heading>
                        </GridItem>

                        <GridItem xs="auto" sm="auto" md={5} lg={5}>
                          <Button
                            width="content"
                            variation="secondary"
                            icon={
                              <Close color="inherit" inverse size="s2" trim />
                            }
                            iconPosition="left"
                            onClick={() => {
                              setShowAnotherScenario(false);
                              setScenarioTwoPayment(undefined);
                              setFieldError(
                                'secondScenarioMortgageBalanceField',
                                undefined,
                              );
                              setFieldError(
                                'secondScenarioTermLengthYearsField',
                                undefined,
                              );
                              setFieldError(
                                'secondScenarioTermLengthMonthsField',
                                undefined,
                              );
                              setFieldError(
                                'secondScenarioInterestRateField',
                                undefined,
                              );
                            }}
                            data-testid={
                              testIds.mortgagePaymentCalculator
                                .removeScenarioBtn
                            }
                          >
                            {MortgagePaymentCalculator.closeScenario}
                          </Button>
                        </GridItem>
                      </Grid>

                      <Field
                        name="secondScenarioMortgageBalanceField"
                        as={TextField}
                        label={
                          MortgagePaymentCalculator.mortgageBalanceFieldLabel
                        }
                        tooltip={
                          <Tooltip
                            id="secondScenariomortgageBalanceFieldToolTip"
                            target={
                              MortgagePaymentCalculator.toolTipDetails
                                .mortgageBalance.title
                            }
                            data-testid={
                              testIds.mortgagePaymentCalculator
                                .secondScenarioMortgageBalanceFieldTooltipTitle
                            }
                          >
                            <Paragraph
                              data-testid={
                                testIds.mortgagePaymentCalculator
                                  .secondScenarioMortgageBalanceFieldTooltipParagraphOne
                              }
                            >
                              {
                                MortgagePaymentCalculator.toolTipDetails
                                  .mortgageBalance.details.paragraphOne
                              }
                            </Paragraph>
                            <Paragraph
                              data-testid={
                                testIds.mortgagePaymentCalculator
                                  .secondScenarioMortgageBalanceFieldTooltipParagraphTwo
                              }
                            >
                              {
                                MortgagePaymentCalculator.toolTipDetails
                                  .mortgageBalance.details.paragraphTwo
                              }
                            </Paragraph>

                            <Paragraph
                              data-testid={
                                testIds.mortgagePaymentCalculator
                                  .secondScenarioMortgageBalanceFieldTooltipParagraphThree
                              }
                            >
                              {
                                MortgagePaymentCalculator.toolTipDetails
                                  .mortgageBalance.details.paragraphThree
                              }
                            </Paragraph>
                            <Paragraph
                              data-testid={
                                testIds.mortgagePaymentCalculator
                                  .secondScenarioMortgageBalanceFieldTooltipParagraphFour
                              }
                            >
                              <Strong>
                                {' '}
                                {
                                  MortgagePaymentCalculator.toolTipDetails
                                    .mortgageBalance.details.paragraphFour
                                }
                              </Strong>
                            </Paragraph>
                            <Paragraph
                              data-testid={
                                testIds.mortgagePaymentCalculator
                                  .secondScenarioMortgageBalanceFieldTooltipParagraphFive
                              }
                            >
                              {
                                MortgagePaymentCalculator.toolTipDetails
                                  .mortgageBalance.details.paragraphFive
                              }
                            </Paragraph>
                          </Tooltip>
                        }
                        value={values.secondScenarioMortgageBalanceField}
                        maxLength={11}
                        placeholder="£"
                        inputWidth={isMobile ? 'fluid' : 'default'}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (!e.target.value.match(/[0-9]/)) {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              '',
                            );
                          }
                          setFieldValue(
                            'secondScenarioMortgageBalanceField',
                            formatNumber(
                              stripNonNumericalCharacters(e.target.value),
                            ) || undefined,
                          );
                        }}
                        error={
                          touched.secondScenarioMortgageBalanceField &&
                          errors.secondScenarioMortgageBalanceField
                        }
                        data-testid={
                          testIds.mortgagePaymentCalculator
                            .secondScenarioMortgageBalanceField
                        }
                      />

                      <Heading
                        size="s3"
                        data-testid="interim-statement-section-heading"
                        marginBottom="01"
                      >
                        {MortgagePaymentCalculator.termLengthHeading}
                      </Heading>
                      <ContentGroup marginBottom="02">
                        <Tooltip
                          id="secondScenariotermLengthTooltip"
                          target={
                            MortgagePaymentCalculator.toolTipDetails.termLength
                              .title
                          }
                          data-testid={
                            testIds.mortgagePaymentCalculator
                              .secondScenarioTermLengthTooltipTitle
                          }
                        >
                          <Paragraph
                            data-testid={
                              testIds.mortgagePaymentCalculator
                                .secondScenarioTermLengthTooltipParagraphOne
                            }
                          >
                            {
                              MortgagePaymentCalculator.toolTipDetails
                                .termLength.details.paragraphOne
                            }
                          </Paragraph>
                          <Paragraph
                            data-testid={
                              testIds.mortgagePaymentCalculator
                                .secondScenarioTermLengthTooltipParagraphTwo
                            }
                          >
                            <Strong>
                              {' '}
                              {
                                MortgagePaymentCalculator.toolTipDetails
                                  .termLength.details.paragraphTwo
                              }
                            </Strong>
                          </Paragraph>
                          <Paragraph
                            data-testid={
                              testIds.mortgagePaymentCalculator
                                .secondScenarioTermLengthTooltipParagraphThree
                            }
                          >
                            {
                              MortgagePaymentCalculator.toolTipDetails
                                .termLength.details.paragraphThree
                            }
                          </Paragraph>
                        </Tooltip>
                      </ContentGroup>
                      <Grid>
                        <GridItem xs={6} sm={6} md={3} lg={4}>
                          <Field
                            name="secondScenarioTermLengthYearsField"
                            as={CurrencyField}
                            showCurrencySymbol={false}
                            label={
                              MortgagePaymentCalculator.termLengthYearsFieldLabel
                            }
                            value={values.secondScenarioTermLengthYearsField}
                            error={
                              touched.secondScenarioTermLengthYearsField &&
                              errors.secondScenarioTermLengthYearsField
                            }
                            maxLength={2}
                            data-testid={
                              testIds.mortgagePaymentCalculator
                                .secondScenarioTermLengthYearsField
                            }
                            inputWidth={isMobile ? 'fluid' : 'default'}
                          />
                        </GridItem>

                        <GridItem xs={6} sm={6} md={3} lg={4}>
                          <Field
                            name="secondScenarioTermLengthMonthsField"
                            as={CurrencyField}
                            showCurrencySymbol={false}
                            label={
                              MortgagePaymentCalculator.termLengthMonthsFieldLabel
                            }
                            value={values.secondScenarioTermLengthMonthsField}
                            maxLength={2}
                            error={
                              touched.secondScenarioTermLengthMonthsField &&
                              errors.secondScenarioTermLengthMonthsField
                            }
                            data-testid={
                              testIds.mortgagePaymentCalculator
                                .secondScenarioTermLengthMonthsField
                            }
                            inputWidth={isMobile ? 'fluid' : 'default'}
                          />
                        </GridItem>
                      </Grid>

                      <Field
                        name="secondScenarioInterestRateField"
                        tooltip={
                          <Tooltip
                            id="secondScenarioInterestRateFieldToolTip"
                            target={
                              MortgagePaymentCalculator.toolTipDetails
                                .interestRate.title
                            }
                            data-testid={
                              testIds.mortgagePaymentCalculator
                                .secondScenarioInterestFieldTooltipTitle
                            }
                          >
                            <Paragraph
                              data-testid={
                                testIds.mortgagePaymentCalculator
                                  .secondScenarioInterestFieldTooltipParagraphOne
                              }
                            >
                              {
                                MortgagePaymentCalculator.toolTipDetails
                                  .interestRate.details.paragraphOne
                              }
                            </Paragraph>
                            <Paragraph
                              data-testid={
                                testIds.mortgagePaymentCalculator
                                  .secondScenarioInterestFieldTooltipParagraphTwo
                              }
                            >
                              <Strong>
                                {' '}
                                {
                                  MortgagePaymentCalculator.toolTipDetails
                                    .interestRate.details.paragraphTwo
                                }
                              </Strong>
                            </Paragraph>
                            <Paragraph
                              data-testid={
                                testIds.mortgagePaymentCalculator
                                  .secondScenarioInterestFieldTooltipParagraphThree
                              }
                            >
                              {
                                MortgagePaymentCalculator.toolTipDetails
                                  .interestRate.details.paragraphThree
                              }
                            </Paragraph>
                          </Tooltip>
                        }
                        as={CurrencyField}
                        showCurrencySymbol={false}
                        label={MortgagePaymentCalculator.interestRateFieldLabel}
                        value={values.secondScenarioInterestRateField}
                        maxLength={5}
                        error={
                          touched.secondScenarioInterestRateField &&
                          errors.secondScenarioInterestRateField
                        }
                        data-testid={
                          testIds.mortgagePaymentCalculator
                            .secondScenarioInterestField
                        }
                        inputWidth={isMobile ? 'fluid' : 'default'}
                      />

                      <Field
                        as={Selector}
                        label="Repayment type"
                        tooltip={
                          <Tooltip
                            id="secondScenarioRepaymentTypeToolTip"
                            target={
                              MortgagePaymentCalculator.toolTipDetails
                                .repaymentType.title
                            }
                            data-testid={
                              testIds.mortgagePaymentCalculator
                                .secondScenarioRepaymentTypeTooltipTitle
                            }
                          >
                            <Paragraph
                              data-testid={
                                testIds.mortgagePaymentCalculator
                                  .repaymentTypeParagraphOne
                              }
                            >
                              {
                                MortgagePaymentCalculator.toolTipDetails
                                  .repaymentType.details.paragraphOne
                              }
                            </Paragraph>
                            <Paragraph
                              data-testid={
                                testIds.mortgagePaymentCalculator
                                  .secondScenarioRepaymentTypeParagraphTwo
                              }
                            >
                              {
                                MortgagePaymentCalculator.toolTipDetails
                                  .repaymentType.details.paragraphTwo
                              }
                            </Paragraph>
                          </Tooltip>
                        }
                        name="secondScenarioRepaymentType"
                        value={values.secondScenarioRepaymentType}
                        supportiveText="Please choose one of these options"
                        variation="Spaced-Auto"
                      >
                        <Field
                          as={SelectorItem}
                          defaultChecked
                          id="secondScenarioRepaymentTypeRepayment"
                          name="secondScenarioRepaymentType"
                          value="repayment"
                          label={
                            MortgagePaymentCalculator.repaymentType.repayment
                          }
                          data-testid={
                            testIds.mortgagePaymentCalculator
                              .secondScenarioRepaymentTypeRepayment
                          }
                        >
                          {MortgagePaymentCalculator.repaymentType.repayment}
                        </Field>

                        <Field
                          as={SelectorItem}
                          id="secondScenarioRepaymentTypeInterest"
                          name="secondScenarioRepaymentType"
                          value="interest-only"
                          label={
                            MortgagePaymentCalculator.repaymentType.interestOnly
                          }
                          data-testid={
                            testIds.mortgagePaymentCalculator
                              .secondScenarioRepaymentTypeInterestOnly
                          }
                        >
                          {MortgagePaymentCalculator.repaymentType.interestOnly}
                        </Field>
                      </Field>
                    </Form>
                  )}
                </GridItem>
                <GridItem sm={12} md={12} lg={4} xl={4}>
                  {scenarioOnePayment !== undefined && (
                    <>
                      <Box
                        bgColor="success"
                        marginTop={isMobile ? '05' : 'none'}
                        data-testid={
                          testIds.mortgagePaymentCalculator
                            .monthlyPaymentContainer
                        }
                      >
                        <Heading size="s4">
                          {
                            MortgagePaymentCalculator.scenarioHeadings
                              .scenarioOne
                          }
                        </Heading>
                        <Heading size="s3" marginBottom="01">
                          {MortgagePaymentCalculator.estimatedMonthlyPayment}
                        </Heading>
                        <Heading
                          size="s5"
                          marginTop="01"
                          data-testid={
                            testIds.mortgagePaymentCalculator.scenarioOnePayment
                          }
                        >
                          £{scenarioOnePayment.toLocaleString()}
                        </Heading>
                        {showAnotherScenario && scenarioTwoPayment && (
                          <>
                            <Hr marginTop="01" marginBottom="03" />
                            <Heading size="s4">
                              {
                                MortgagePaymentCalculator.scenarioHeadings
                                  .scenarioTwo
                              }
                            </Heading>
                            <Heading size="s3" marginBottom="01">
                              {
                                MortgagePaymentCalculator.estimatedMonthlyPayment
                              }
                            </Heading>
                            <Grid>
                              <GridItem sm="auto">
                                <Heading
                                  size="s5"
                                  marginTop="03"
                                  data-testid={
                                    testIds.mortgagePaymentCalculator
                                      .scenarioTwoPayment
                                  }
                                >
                                  £{scenarioTwoPayment.toLocaleString()}
                                </Heading>
                              </GridItem>
                              <GridItem sm="auto">
                                <Box bgColor="success">
                                  <Heading
                                    marginBottom="none"
                                    size="s3"
                                    marginTop="none"
                                    data-testid={
                                      testIds.mortgagePaymentCalculator
                                        .monthlyPaymentChange
                                    }
                                  >
                                    {scenarioOnePayment &&
                                      scenarioTwoPayment &&
                                      calculateAndFormatDifference(
                                        scenarioOnePayment,
                                        scenarioTwoPayment,
                                      )}
                                  </Heading>
                                </Box>
                              </GridItem>
                            </Grid>
                          </>
                        )}
                      </Box>
                      <Paragraph marginTop="none">
                        {
                          MortgagePaymentCalculator.repaymentText
                            .illustrativePurposes
                        }
                      </Paragraph>
                    </>
                  )}
                </GridItem>

                <GridItem sm="auto">
                  <Button
                    type="submit"
                    width="content"
                    data-testid={testIds.mortgagePaymentCalculator.calculateBtn}
                    onClick={() => {
                      if (!isValid || hasEmptyFields(values)) {
                        setShowSummaryError(true);
                        window.scrollTo({ top: 140, behavior: 'smooth' });
                      } else {
                        handleSubmit();
                      }
                    }}
                  >
                    Calculate
                  </Button>
                </GridItem>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </Main>
  );
}
