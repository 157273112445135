export const privacy = {
  heading: 'privacy-page-heading',
  ourPromiseInfoPanel: {
    sectionHeading: 'privacy-page-our-promise-panel-section-heading',
    sectionBody: 'privacy-page-our-promise-panel-section-body',
  },
  firstInfoPanel: {
    sectionHeading: 'privacy-page-first-panel-section-heading',
    sectionBody: 'privacy-page-first-panel-section-body',
  },

  secondInfoPanel: {
    sectionHeading: 'privacy-page-second-panel-section-heading',
    sectionBody: 'privacy-page-second-panel-section-body',
  },

  thirdInfoPanel: {
    sectionHeading: 'privacy-page-third-panel-section-heading',
    sectionBody: 'privacy-page-third-panel-section-body',
  },

  fourthInfoPanel: {
    sectionHeading: 'privacy-page-fourth-panel-section-heading',
    sectionBody: 'privacy-page-fourth-panel-section-body',
  },

  fifthInfoPanel: {
    sectionHeading: 'privacy-page-fifth-panel-section-heading',
    sectionBody: 'privacy-page-fifth-panel-section-body',
  },
  sixthInfoPanel: {
    sectionHeading: 'privacy-page-fifth-panel-section-heading',
    sectionBody: 'privacy-page-fifth-panel-section-body',
  },
  groupsOfPersonalInfo: {
    heading: 'privacy-page-groups-of-personal-info-heading',
    subheading: 'privacy-page-groups-of-personal-info-sub-heading',
    paragraph1: 'privacy-page-groups-of-personal-info-paragraph-1',
    paragraph2: 'privacy-page-groups-of-personal-info-paragraph-2',
    specialCategoriesFirstLine:
      'privacy-page-groups-of-personal-info-special-categories-first-line',
    specialCategoriesSecondLine:
      'privacy-page-groups-of-personal-info-special-categories-second-line',
    specialCategoriesType:
      'privacy-page-groups-of-personal-info-special-categories-type',
  },
  bankingAndFinancialServicesList: {
    craItem: 'privacy-page-banking-and-financial-services-list-cra-item',
    craItemItalic:
      'privacy-page-banking-and-financial-services-list-cra-item-italic',
    linkedpPersonItem:
      'privacy-page-banking-and-financial-services-list-linked-person-item',
    financialServicesCompanyitem:
      'privacy-page-banking-and-financial-services-list-financial-services-company-item',
    financialAdvisorsItem:
      'privacy-page-banking-and-financial-services-list-financial-advisors-item',
    comparisonWebsitesItem:
      'privacy-page-banking-and-financial-services-list-comparison-websites-item',
    employersItem:
      'privacy-page-banking-and-financial-services-list-employers-item',
    companiesItem:
      'privacy-page-banking-and-financial-services-list-companies-item',
  },
  insurers: {
    heading: 'privacy-page-insurers-heading',
    paragraph: 'privacy-page-insurers-paragraph',
  },
  otherServicesAndSchemes: {
    heading: 'privacy-page-other-services-and-schemes-heading',
    paragraph1: 'privacy-page-other-services-and-schemes-paragraph-1',
    listItem: 'privacy-page-other-services-and-schemes-list-item',
  },
  generalBusiness: {
    heading: 'privacy-page-general-business-heading',
    paragraph: 'privacy-page-general-business-paragraph',
    item0: 'privacy-page-general-business-iterm-0',
    item1: 'privacy-page-general-business-iterm-1',
    item2: 'privacy-page-general-business-iterm-2',
    item3: 'privacy-page-general-business-iterm-3',
    item4: 'privacy-page-general-business-iterm-4',
    item5: 'privacy-page-general-business-iterm-5',
  },
  companyMergers: {
    heading: 'privacy-page-company-mergers-heading',
    paragraph: 'privacy-page-company-mergers-paragraph',
    item0: 'privacy-page-company-mergers-iterm-0',
    item1: 'privacy-page-company-mergers-iterm-1',
    item2: 'privacy-page-company-mergers-iterm-2',
  },

  dataTransfer: {
    heading: 'privacy-page-data-transfer-heading',
    subheading: 'privacy-page-data-transfer-sub-heading',
    paragraph1: 'privacy-page-data-transfer-paragraph-1',
    paragraph2: 'privacy-page-data-transfer-paragraph-2',
    item0: 'privacy-page-data-transfer-list-item-0',
    item1: 'privacy-page-data-transfer-list-item-1',
    item2: 'privacy-page-data-transfer-list-item-2',
    safeGaurdItem0: 'privacy-page-data-transfer-safe-gaurd-item-0',
    safeGaurdItem1: 'privacy-page-data-transfer-safe-gaurd-item-1',
  },
  personalInformation: {
    heading: 'privacy-page-personal-information-heading',
    paragraph1: 'privacy-page-personal-information-paragraph-1',
  },
  dataProtectionOfficer: {
    heading: 'privacy-page-data-protection-officer-heading',
    paragraph1: 'privacy-page-data-protection-officer-paragraph-1',
  },
  unhappyWithDataUsage: {
    heading: 'privacy-page-unhappy-with-data-usage-heading',
    paragraph1: 'privacy-page-unhappy-with-data-usage-paragraph-1',
    paragraph2: 'privacy-page-unhappy-with-data-usage-paragraph-2',
  },
  outsideCompanies: {
    paragraph1: 'privacy-page-outside-companies-paragraph-1',
    paragraph2: 'privacy-page-outside-companies-paragraph-2',
  },
};
