import accessibility from './accessibility';
import { appLayout } from './appLayout';
import bankOfEnglandBaseRate from './bankOfEnglandBaseRate';
import complaints from './complaints';
import contactUs from './contactUs';
import cookies from './cookies';
import eligibility from './eligibility';
import feesAndCharges from './feesAndCharges';
import home from './home';
import howToMakeAPayment from './howToMakeAPayment';
import incomeAndExpenditure from './incomeAndExpenditure';
import interestAndProducts from './interestAndProducts';
import knowledgeBase from './knowledgeBase';
import landlordSupport from './landlordSupport';
import makingOverpayments from './makingOverpayments';
import manageYourMortgage from './manageYourMortgage';
import mortgageCalculators from './mortgageCalculators';
import mortgagePaymentCalculator from './mortgagePaymentCalculator';
import notfound from './notfound';
import ourMortgages from './ourMortgages';
import payments from './payments';
import portfolioLandlord from './portfolioLandlord';
import privacy from './privacy';
import propertyDeeds from './propertyDeeds';
import questionsAboutLending from './questionsaboutlending';
import rateChangeCalculator from './rateChangeCalculator';
import siteMap from './siteMap';
import sustainability from './sustainability';
import termsAndConditions from './termsAndConditions';
import worriedAboutFuturePayments from './worriedAboutFuturePayments';
import { AppContent } from '../AppContent';

const content: AppContent = {
  appLayout,
  knowledgeBase,
  howToMakeAPayment,
  makingOverpayments,
  interestAndProducts,
  questionsAboutLending,
  eligibility,
  manageYourMortgage,
  sustainability,
  portfolioLandlord,
  bankOfEnglandBaseRate,
  worriedAboutFuturePayments,
  contactUs,
  feesAndCharges,
  home,
  mortgageCalculators,
  notfound,
  ourMortgages,
  payments,
  complaints,
  propertyDeeds,
  rateChangeCalculator,
  cookies,
  termsAndConditions,
  accessibility,
  mortgagePaymentCalculator,
  privacy,
  siteMap,
  incomeAndExpenditure,
  landlordSupport,
};

export default content;
