// we need a separate file so that both the server build & client build can share the same content file, attempting to use
// the content files from the client folder will break if they contain anything other than text (eg React components)
import { MetaContent } from './MetaContent';

const home: MetaContent = {
  title: 'Home | Birmingham Midshires',
  description:
    "Birmingham Midshires is a specialist buy to let provider, we want you to feel confident you've made the right decision with your mortgage",
};

const ourMortgages: MetaContent = {
  title: 'Our mortgages | Birmingham Midshires',
  description:
    'Birmingham Midshires has a range of buy to let mortgages to suit your needs, whether you have a single property or lots of different properties to rent out',
};

const manageYourMortgage: MetaContent = {
  title: 'Managing your mortgage | Birmingham Midshires',
  description:
    'Find out how to manage your Birmingham Midshires mortgage online, borrow more, switch your deal, and everything else you need to know',
};

const mortgageCalculators: MetaContent = {
  title: 'Calculators | Birmingham Midshires',
  description:
    'Our mortgage calculators can make things easier to understand for you. Simply select the one that suits your need and answer a few questions',
};

const sustainability: MetaContent = {
  title: 'Sustainability | Birmingham Midshires',
  description:
    'Find out about your obligations as a landlord and what government schemes are available to help you and your tenants make your property more sustainable',
};

const knowledgeBase: MetaContent = {
  title: 'Knowledge base | Birmingham Midshires',
  description:
    'Already got a mortgage with Birmingham Midshires? Explore the knowledge base sections to find everything you need to know',
};

const contactUs: MetaContent = {
  title: 'Contact us | Birmingham Midshires',
  description:
    'Want to get in touch? Find the contact details you need for Birmingham Midshires mortgages',
};

const landlordSupport: MetaContent = {
  title: 'Landlord Support | Birmingham Midshires',
  description:
    "Birmingham Midshires is a specialist buy to let provider, we want you to feel confident you've made the right decision with your mortgage",
};

const accessibility: MetaContent = {
  title: 'Accessibility | Birmingham Midshires',
};

const bankOfEnglandBaseRate: MetaContent = {
  title: 'Bank of England Base Rate | Knowledge base | Birmingham Midshires',
};

const complaints: MetaContent = {
  title: 'Complaints | Knowledge base | Birmingham Midshires',
};

const cookiesPolicy: MetaContent = {
  title: 'Cookies Policy | Birmingham Midshires',
};

const eligibility: MetaContent = {
  title: 'Eligibility | Our mortgages | Birmingham Midshires',
};

const feesAndCharges: MetaContent = {
  title: 'Fees and Charges | Knowledge base | Birmingham Midshires',
};

const worriedAboutFuturePayments: MetaContent = {
  title: 'Worried About Future Payments | Knowledge base | Birmingham Midshires',
};

const howToMakeAPayment: MetaContent = {
  title: 'How to Make a Payment | Knowledge base | Birmingham Midshires',
};

const incomeAndExpenditure: MetaContent = {
  title: 'Income and Expenditure | Knowledge base | Birmingham Midshires',
};

const interestAndProducts: MetaContent = {
  title: 'Interest and Products | Knowledge base | Birmingham Midshires',
};

const questionsAboutLending: MetaContent = {
  title: 'Questions About Lending | Knowledge base | Birmingham Midshires',
};

const makingOverpayments: MetaContent = {
  title: 'Making Overpayments | Knowledge base | Birmingham Midshires',
};

const payments: MetaContent = {
  title: 'Payments | Birmingham Midshires',
};

const portfolioLandlord: MetaContent = {
  title: 'Portfolio Landlord | Our mortgages | Birmingham Midshires',
};

const privacyPolicy: MetaContent = {
  title: 'Privacy Policy | Birmingham Midshires',
};

const propertyDeeds: MetaContent = {
  title: 'Property Deeds | Knowledge base | Birmingham Midshires',
};

const rateChangeCalculator: MetaContent = {
  title: 'Rate Change Calculator | Calculators | Birmingham Midshires',
};

const mortgagePaymentCalculator: MetaContent = {
  title: 'Mortgage Payment Calculator | Calculators | Birmingham Midshires',
};

const siteMap: MetaContent = {
  title: 'Sitemap | Birmingham Midshires',
};

const termsAndConditions: MetaContent = {
  title: 'Terms and Conditions | Birmingham Midshires',
};


export const MetaContentValues = {
  home,
  ourMortgages,
  manageYourMortgage,
  mortgageCalculators,
  sustainability,
  knowledgeBase,
  landlordSupport,
  contactUs,
  accessibility,
  bankOfEnglandBaseRate,
  complaints,
  cookiesPolicy,
  eligibility,
  feesAndCharges,
  worriedAboutFuturePayments,
  howToMakeAPayment,
  incomeAndExpenditure,
  interestAndProducts,
  questionsAboutLending,
  makingOverpayments,
  payments,
  portfolioLandlord,
  privacyPolicy,
  propertyDeeds,
  rateChangeCalculator,
  mortgagePaymentCalculator,
  siteMap,
  termsAndConditions,
};