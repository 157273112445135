export const Accessibility = '/accessibility';
export const BankOfEnglandBaseRate =
  '/knowledge-base/bank-of-england-base-rate';
export const Complaints = '/knowledge-base/complaints';
export const ContactUs = '/contact-us';
export const CookiesPolicy = '/cookies-policy';
export const Eligibility = '/our-mortgages/eligibility';
export const FeesAndCharges = '/knowledge-base/fees-and-charges';
export const WorriedAboutFuturePayments =
  '/knowledge-base/worried-about-future-payments';
export const Home = '/';
export const HowToMakeAPayment =
  '/knowledge-base/how-to-make-a-payment-to-your-mortgage';
export const IncomeAndExpenditure =
  '/knowledge-base/worried-about-future-payments/income-and-expenditure';
export const InterestAndProducts = '/knowledge-base/interest-and-products';
export const KnowledgeBase = '/knowledge-base';
export const QuestionsAboutLending = '/knowledge-base/questions-about-lending';
export const MakingOverpayments = '/knowledge-base/making-overpayments';
export const ManageYourMortgage = '/manage-your-mortgage';
export const MortgageCalculators = '/mortgage-calculators';
export const MortgagePaymentCalculator =
  '/mortgage-calculators/mortgage-payment-calculator';
export const OurMortgages = '/our-mortgages';
export const Payments = '/payments';
export const PortfolioLandlord = '/our-mortgages/portfolio-landlord';
export const PrivacyPolicy = '/privacy-policy';
export const PropertyDeeds = '/knowledge-base/your-property-deeds';
export const RateChangeCalculator =
  '/mortgage-calculators/rate-change-calculator';
export const SiteMap = '/site-map';
export const Sustainability = '/sustainability';
export const TermsAndConditions = '/terms-and-conditions';
export const LandlordSupport = '/landlord-support';
