import React from 'react';

import { Main, Heading, ContentGroup } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { InfoPanel } from 'client/components/infoPanel';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import testIds from 'client/testIds';
import { Helmet } from 'react-helmet';

import { PortfolioLandlordPageContent } from './PortfolioLandlordPage.config';
import * as routes from '../../manifest';

export default function PortfolioLandlordPage() {
  const { pageHeading, infoPanel } = useContent<PortfolioLandlordPageContent>();

  return (
    <Main>
      <Helmet>
        <title>
          Portfolio landlords | Our mortgages | Birmingham Midshires{' '}
        </title>
      </Helmet>
      <ContentGroup marginTop="05">
        <StyledLink
          iconPosition="left"
          data-testid="back-button-link"
          to={routes.OurMortgages}
        >
          Back to Our mortgages
        </StyledLink>
      </ContentGroup>
      <Heading
        as="h1"
        size="s7"
        data-testid={testIds.portfolioLandlord.heading}
        marginTop="05"
      >
        {pageHeading}
      </Heading>

      <InfoPanel
        infoPanel={infoPanel}
        pageTestIds={testIds.portfolioLandlord}
      />
    </Main>
  );
}
